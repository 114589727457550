import { useQuery } from '@apollo/client';
import { STORE_ITUNES, STORE_YOUTUBE } from 'src/constants/stores';
import { TOTAL_LOAD_LIMIT } from 'src/pages/chart/components/chartPageBody/constants';
import { normalizeNumber } from 'src/utils';
import {
    UnionedChartRankingsV2,
    selectChartRankingsV2,
    streamingOfferTypes,
} from '../../selectors';
import chartRankingsInCatalogV2WithGSRQuery from './chartRankingsInCatalogV2WithGSR.gql';
import chartRankingsInCatalogV2WithProductQuery from './chartRankingsInCatalogV2WithProduct.gql';
import chartRankingsInCatalogV2WithVideoQuery from './chartRankingsInCatalogV2WithVideo.gql';
import chartRankingsV2WithGSRQuery from './chartRankingsV2WithGSR.gql';
import chartRankingsV2WithProductQuery from './chartRankingsV2WithProduct.gql';
import chartRankingsV2WithVideoQuery from './chartRankingsV2WithVideo.gql';

interface ChartRankingsV2Query {
    chartRankingsV2: UnionedChartRankingsV2[];
}

interface ChartRankingsInCatalogV2Query {
    chartRankingsInCatalogV2: UnionedChartRankingsV2[];
}

interface ChartRankingsVariables {
    id: string;
    timestamp: string;
    isBrandsV1Enabled: boolean;
    isBrandsV2Enabled: boolean;
    isSpotifyEnabled: boolean;
    isEmployee: boolean;
    limit: number;
    offset: number;
}

const getChartTypeQuery = (platformName?: string, inCatalog?: boolean) => {
    switch (platformName) {
        case STORE_YOUTUBE:
            return inCatalog
                ? chartRankingsInCatalogV2WithVideoQuery
                : chartRankingsV2WithVideoQuery;

        case STORE_ITUNES:
            return inCatalog
                ? chartRankingsInCatalogV2WithProductQuery
                : chartRankingsV2WithProductQuery;

        default:
            return inCatalog
                ? chartRankingsInCatalogV2WithGSRQuery
                : chartRankingsV2WithGSRQuery;
    }
};

export const useChartRankingsV2Query = (
    variables: ChartRankingsVariables,
    platformName?: string,
    skip = false
) => {
    const query = getChartTypeQuery(platformName);
    const { data, loading, error } = useQuery<
        ChartRankingsV2Query,
        ChartRankingsVariables
    >(query, { variables, skip: !variables.timestamp || skip });

    const formattedData = selectChartRankingsV2(data?.chartRankingsV2);

    return {
        loading,
        data: formattedData,
        error,
    };
};

export const useChartRankingsInCatalogV2Query = (
    variables: ChartRankingsVariables,
    platformName?: string,
    skip = false
) => {
    const query = getChartTypeQuery(platformName, true);
    const { data, loading, error } = useQuery<
        ChartRankingsInCatalogV2Query,
        ChartRankingsVariables
    >(query, { variables, skip: !variables.timestamp || skip });

    const formattedData = selectChartRankingsV2(data?.chartRankingsInCatalogV2);

    return {
        loading,
        data: formattedData,
        error,
    };
};

export const useChartRankingsQuery = (
    variables: ChartRankingsVariables,
    isCatalog: boolean,
    platformName?: string,
    skip?: boolean
) => {
    const chartRankingsData = useChartRankingsV2Query(
        variables,
        platformName,
        skip
    );

    // @TODO: `limit` here is a workaround to make the counter work correctly, should be fixed a cleaner way
    const chartRankingsInCatalogData = useChartRankingsInCatalogV2Query(
        { ...variables, limit: TOTAL_LOAD_LIMIT },
        platformName,
        skip
    );

    const { data: catalogData, loading: catalogLoading } =
        chartRankingsInCatalogData;
    const { loading } = chartRankingsData;
    const isDataLoading = isCatalog ? catalogLoading : loading;

    // Use in Catalog filter logic to account for FF (like show_sme_data)
    const inCatalogCount = catalogLoading
        ? null
        : catalogData.filter(({ offerTypes }) =>
              offerTypes?.some(type => streamingOfferTypes.includes(type))
          ).length;

    return {
        ...(isCatalog ? chartRankingsInCatalogData : chartRankingsData),
        loading: isDataLoading,
        inCatalogCount: normalizeNumber(inCatalogCount),
    };
};
