import { useMemo } from 'react';
import { gql, useApolloClient, ApolloClient } from '@apollo/client';
import {
    useIdentity,
    Identity,
    formatMessage,
} from '@theorchard/suite-frontend';
import { flatMap } from 'lodash';
import { selectTimeseries } from 'src/apollo/selectors';
import { useDeepMemo, useParallelQueries } from 'src/apollo/utils';
import { TOTAL_ROW_NAME } from 'src/components/metricsOverTime/constants';
import { parsePrimaryDimension, parseSecondaryDimension } from './dimension';
import { getQuery } from './queries';
import { getSelector } from './selectors';
import {
    DownloadsQuery,
    DownloadsVariables,
    PrimaryDimension,
    SecondaryDimension,
    StreamsQuery,
    StreamsVariables,
    TimeseriesOptions,
    Variables,
} from './types';
import { getTimeseriesSummary } from './utils';

export const PlaceholderQuery = gql`
    query {
        thisIsGoingToFailIfExecuted
    }
`;

const selectTimeseriesData = ({
    streamsData,
    downloadsData,
    variables,
    primaryDimension,
    secondaryDimension,
    datePeriod,
}: {
    streamsData?: StreamsQuery[];
    downloadsData?: DownloadsQuery[];
    variables: Variables[];
    primaryDimension: PrimaryDimension;
    secondaryDimension: SecondaryDimension;
    datePeriod: string;
}) => {
    if (!streamsData && !downloadsData) return [];

    const data = variables.map(({ isrc, name, releaseDate }) => ({
        isrc,
        name,
        releaseDate,
        streams:
            streamsData?.find(
                ({ globalSoundRecordingByIsrc }) =>
                    globalSoundRecordingByIsrc?.isrc === isrc
            )?.globalSoundRecordingByIsrc?.analytics ?? undefined,
        downloads:
            downloadsData?.find(
                ({ globalSoundRecordingByIsrc }) =>
                    globalSoundRecordingByIsrc?.isrc === isrc
            )?.globalSoundRecordingByIsrc?.analytics ?? undefined,
    }));

    return getSelector(secondaryDimension)({
        data,
        primaryDimension,
        datePeriod,
    });
};

const useTotalsSummary = ({
    variables,
    identity,
    client,
    skip,
}: {
    variables: Variables[];
    identity: Identity;
    client: ApolloClient<object>;
    skip?: boolean;
}) => {
    const { streamsQuery, downloadsQuery } = getQuery(SecondaryDimension.Total)(
        identity
    );
    const {
        data: streamsData,
        loading: streamsLoading,
        error: streamsError,
    } = useParallelQueries<StreamsQuery, StreamsVariables>(
        streamsQuery ?? PlaceholderQuery,
        { client, variables, skip: skip || !streamsQuery }
    );

    const {
        data: downloadsData,
        loading: downloadsLoading,
        error: downloadsError,
    } = useParallelQueries<DownloadsQuery, DownloadsVariables>(
        downloadsQuery ?? PlaceholderQuery,
        { client, variables, skip: skip || !downloadsQuery }
    );

    const totalSummaryData = useMemo(() => {
        if (!streamsData && !downloadsData) return [];

        const data = variables.map(({ isrc }) => ({
            isrc,
            streams:
                streamsData?.find(
                    ({ globalSoundRecordingByIsrc }) =>
                        globalSoundRecordingByIsrc?.isrc === isrc
                )?.globalSoundRecordingByIsrc?.analytics ?? undefined,
            downloads:
                downloadsData?.find(
                    ({ globalSoundRecordingByIsrc }) =>
                        globalSoundRecordingByIsrc?.isrc === isrc
                )?.globalSoundRecordingByIsrc?.analytics ?? undefined,
        }));
        return flatMap(data, ({ isrc, streams, downloads }) => {
            const streamTotals = streams?.streamsTotal;
            const downloadTotals = downloads?.downloadsTotal;

            return getTimeseriesSummary({
                id: isrc,
                name: TOTAL_ROW_NAME,
                title: formatMessage('total'),
                streams: streamTotals?.items
                    ? selectTimeseries(streamTotals.items)
                    : null,
                downloads: downloadTotals?.items,
                className: TOTAL_ROW_NAME,
            });
        });
    }, [variables, streamsData, downloadsData]);

    return {
        data: totalSummaryData,
        loading: streamsLoading || downloadsLoading,
        error: streamsError || downloadsError,
    };
};

export const useSongPerformanceOverTimeQuery = (
    variables: Variables[],
    options: TimeseriesOptions
) => {
    const { datePeriod } = options;
    const identity = useIdentity();
    const client = useApolloClient();
    const memoizedVars = useDeepMemo(() => variables, [variables]);

    const primaryDimension = parsePrimaryDimension(options.primaryDimension);
    const secondaryDimension = parseSecondaryDimension(
        options.secondaryDimension
    );
    const { streamsQuery, downloadsQuery } =
        getQuery(secondaryDimension)(identity);

    const {
        data: streamsData,
        loading: streamsLoading,
        error: streamsError,
    } = useParallelQueries<StreamsQuery, StreamsVariables>(
        streamsQuery ?? PlaceholderQuery,
        { client, variables, skip: !streamsQuery }
    );

    const {
        data: downloadsData,
        loading: downloadsLoading,
        error: downloadsError,
    } = useParallelQueries<DownloadsQuery, DownloadsVariables>(
        downloadsQuery ?? PlaceholderQuery,
        { client, variables, skip: !downloadsQuery }
    );

    const timeseriesData = useMemo(
        () =>
            selectTimeseriesData({
                streamsData,
                downloadsData,
                datePeriod,
                variables: memoizedVars,
                primaryDimension,
                secondaryDimension,
            }),
        [
            streamsData,
            downloadsData,
            memoizedVars,
            primaryDimension,
            secondaryDimension,
            datePeriod,
        ]
    );

    return {
        data: timeseriesData,
        loading: streamsLoading || downloadsLoading,
        error: streamsError || downloadsError,
    };
};

export const useSongPerformanceOverTimeTotalsQuery = (
    variables: Variables[],
    secondaryDimension: string
) => {
    const identity = useIdentity();
    const client = useApolloClient();
    const secondaryDimensionAsParameter =
        parseSecondaryDimension(secondaryDimension);
    const {
        data: totalsMetricData,
        loading: totalLoading,
        error: totalError,
    } = useTotalsSummary({
        skip: secondaryDimensionAsParameter === SecondaryDimension.Total,
        variables,
        identity,
        client,
    });

    return {
        data: totalsMetricData,
        loading: totalLoading,
        error: totalError,
    };
};
