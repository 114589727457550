import React from 'react';
import { GlyphButton, GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { get } from 'lodash';
import { PlaylistType } from 'src/apollo/selectors/playlist';

export const CLASSNAME = 'PositionWithButton';
export const POSITION_CLASSNAME = `${CLASSNAME}-position`;
export const BUTTON_CLASSNAME = `${CLASSNAME}-button`;

interface onClickHandlerProp {
    (data: PlaylistType): void;
}

GridTable.defineColumnTemplate('positionWithButton', {
    align: 'right',
    Cell: ({ data, column: { definition } }) => {
        const { position, size, storefrontCount } = data;
        const onClickHandler = get(definition, 'data-onClickHandler') as
            | onClickHandlerProp
            | undefined;

        return (
            <span className={CLASSNAME}>
                {position && size && (
                    <span className={POSITION_CLASSNAME}>
                        {formatMessage('playlists.info.position', {
                            position,
                            total: size,
                        })}
                    </span>
                )}
                {!storefrontCount && (
                    <GlyphButton
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();

                            onClickHandler?.(data);
                        }}
                        variant="quartenary"
                        name="position"
                        className={BUTTON_CLASSNAME}
                    />
                )}
            </span>
        );
    },
});
