import { useCallback } from 'react';
import {
    ApolloError,
    QueryLazyOptions,
    useLazyQuery,
    useQuery,
} from '@apollo/client';
import {
    GlobalParticipantDropdownSearchES,
    GlobalParticipantDropdownSearchESVariables,
} from 'src/apollo/definitions/GlobalParticipantDropdownSearchES';
import {
    GlobalParticipantSearchES,
    GlobalParticipantSearchESVariables,
} from 'src/apollo/definitions/GlobalParticipantSearchES';
import {
    GlobalParticipantSearchFacets,
    GlobalParticipantSearchFacetsVariables,
    GlobalParticipantSearchFacets_globalParticipantSearchES_facets as SearchFacets,
} from 'src/apollo/definitions/GlobalParticipantSearchFacets';
import {
    ParticipantDropdownSearchResult,
    ParticipantSearchResult,
    selectGlobalParticipantDropdownSearchESResults,
    selectGlobalParticipantSearchESResults,
    selectGlobalParticipantSearchESResultsV2,
} from '../../selectors/participantSearch';
import dropdownSearchParticipantQueryES from './globalParticipantDropdownSearchES.gql';
import searchParticipantQueryES from './globalParticipantSearchES.gql';
import globalParticipantSearchFacets from './globalParticipantSearchFacets.gql';

export interface ParticipantSearchQueryResult<T = ParticipantSearchResult> {
    loading: boolean;
    error?: ApolloError;
    data?: T;
}

export interface ParticipantSearchQueryResultV2<T = ParticipantSearchResult> {
    loading: boolean;
    error?: ApolloError;
    data?: T;
    variables?: GlobalParticipantSearchESVariables;
}

export interface ParticipantSearchQueryResultWithBrands
    extends GlobalParticipantSearchESVariables {
    shouldIncludeLabelsCompanyBrand?: boolean;
}

interface ParticipantSearchHandler {
    (options?: QueryLazyOptions<GlobalParticipantSearchESVariables>): Promise<
        ParticipantSearchQueryResult<ParticipantSearchResult>
    >;
}

export type ParticipantSearchLazyQueryResult = [
    ParticipantSearchHandler,
    ParticipantSearchQueryResult
];

interface ParticipantDropdownSearchHandler {
    (
        options?: QueryLazyOptions<GlobalParticipantDropdownSearchESVariables>
    ): Promise<ParticipantSearchQueryResult<ParticipantDropdownSearchResult>>;
}

export type ParticipantDropdownSearchLazyQueryResult = [
    ParticipantDropdownSearchHandler,
    ParticipantSearchQueryResult<ParticipantDropdownSearchResult>
];

export const useLazyParticipantSearch =
    (): ParticipantSearchLazyQueryResult => {
        const [internalExecuteSearch, { data, loading, error }] = useLazyQuery<
            GlobalParticipantSearchES,
            GlobalParticipantSearchESVariables
        >(searchParticipantQueryES);

        const executeSearch = useCallback<ParticipantSearchHandler>(
            async args => {
                const { data: searchData } = await internalExecuteSearch(args);
                return {
                    loading: false,
                    data:
                        searchData &&
                        selectGlobalParticipantSearchESResults(searchData),
                };
            },
            [internalExecuteSearch]
        );

        return [
            executeSearch,
            {
                loading,
                data: data && selectGlobalParticipantSearchESResults(data),
                error,
            },
        ];
    };

export const useLazyParticipantDropdownSearch =
    (): ParticipantDropdownSearchLazyQueryResult => {
        const [internalExecuteSearch, { data, loading, error }] = useLazyQuery<
            GlobalParticipantDropdownSearchES,
            GlobalParticipantDropdownSearchESVariables
        >(dropdownSearchParticipantQueryES);

        const executeSearch = useCallback<ParticipantDropdownSearchHandler>(
            async args => {
                const { data: searchData } = await internalExecuteSearch(args);
                return {
                    loading: false,
                    data:
                        searchData &&
                        selectGlobalParticipantDropdownSearchESResults(
                            searchData
                        ),
                };
            },
            [internalExecuteSearch]
        );

        return [
            executeSearch,
            {
                loading,
                data:
                    data &&
                    selectGlobalParticipantDropdownSearchESResults(data),
                error,
            },
        ];
    };

export const useParticipantSearchQuery = ({
    variables,
    skip,
}: {
    variables: GlobalParticipantSearchESVariables;
    skip?: boolean;
}): ParticipantSearchQueryResult => {
    const { loading, error, data } = useQuery<
        GlobalParticipantSearchES,
        GlobalParticipantSearchESVariables
    >(searchParticipantQueryES, {
        variables,
        skip,
    });

    return {
        loading,
        data: data && selectGlobalParticipantSearchESResults(data),
        error,
    };
};

export const useParticipantSearchQueryV2 = ({
    variables,
    skip,
}: {
    variables: ParticipantSearchQueryResultWithBrands;
    skip?: boolean;
}): ParticipantSearchQueryResultV2 => {
    const { loading, error, data } = useQuery<
        GlobalParticipantSearchES,
        ParticipantSearchQueryResultWithBrands
    >(searchParticipantQueryES, {
        variables,
        skip,
    });

    return {
        loading,
        data: data && selectGlobalParticipantSearchESResultsV2(data),
        error,
    };
};

const EMPTY_FACETS: SearchFacets = {
    vendors: { items: [] },
    countries: { items: [] },
};

export const useParticipantSearchFacetsQuery = (
    variables: GlobalParticipantSearchFacetsVariables
) => {
    const { loading, error, data } = useQuery<
        GlobalParticipantSearchFacets,
        GlobalParticipantSearchFacetsVariables
    >(globalParticipantSearchFacets, { variables, fetchPolicy: 'cache-first' });

    return {
        loading,
        data: data?.globalParticipantSearchES.facets ?? EMPTY_FACETS,
        error,
    };
};
