import React, { FC } from 'react';
import { GridTableCellProps, Tooltip } from '@theorchard/suite-components';
import { formatNumber } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import { get, isNull, uniqueId } from 'lodash';
import { EMPTY_CHAR } from 'src/constants';
import { formatPercentage, normalizeNumber } from 'src/utils';

export interface CellProps extends GridTableCellProps<object> {
    percentageProperty: string;
    currentValueProperty: string;
}

const NumberWithPercentageAndArrowCell: FC<CellProps> = ({
    data,
    value,
    percentageProperty,
    currentValueProperty,
}) => {
    let change: number | null = null;
    let isPercentageIncreased = false;

    const percentage: number | null = get(data, percentageProperty);
    const currentValue = get(data, currentValueProperty);
    const currentNormalizedValue = normalizeNumber(currentValue);
    const normalizedPrevValue = normalizeNumber(value);
    let percentageValue = normalizeNumber(percentage);
    const isNewSong = percentageValue === 0;

    if (!isNull(normalizedPrevValue) && !isNull(currentNormalizedValue)) {
        change = currentNormalizedValue - normalizedPrevValue;
    }
    const isChangeDataAbsent = change === 0 && isNewSong;

    if (!isChangeDataAbsent) {
        percentageValue = isNewSong ? 1 : percentageValue;
    }

    if ((isNull(percentageValue) || isNewSong) && isNull(change)) {
        return <span>{EMPTY_CHAR}</span>;
    }

    let percentageContent = (
        <span className="percentage">
            {!isNull(percentageValue)
                ? `(${formatPercentage(percentageValue)})`
                : '(N/A)'}
        </span>
    );

    if (!isNull(percentageValue)) {
        isPercentageIncreased = percentageValue > 0;
    } else if (!isNull(change)) {
        isPercentageIncreased = change > 0;
    }

    if (!isNull(percentageValue) && percentageValue > 9.99) {
        percentageContent = (
            <Tooltip
                id={`NumberWithPercentageAndArrowCell_${percentageValue}_${uniqueId()}`}
                message={formatPercentage(percentageValue)}
            >
                <span className="percentage">{`(${formatNumber(
                    percentageValue * 100
                )}%)`}</span>
            </Tooltip>
        );
    }

    const className = isPercentageIncreased
        ? 'percentageIncreaseWithArrow'
        : 'percentageDecreaseWithArrow';

    return (
        <div className={cx('NumberWithPercentageAndArrowCell', className)}>
            {!isChangeDataAbsent && (
                <GlyphIcon
                    name={isPercentageIncreased ? 'arrowUp' : 'arrowDown'}
                    size={12}
                />
            )}
            <span className="change">
                {isNull(change) ? 'N/A' : formatNumber(change)}
            </span>
            {percentageContent}
        </div>
    );
};

export default NumberWithPercentageAndArrowCell;
