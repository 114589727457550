import { useCallback } from 'react';
import {
    ApolloError,
    QueryLazyOptions,
    useLazyQuery,
    useQuery,
} from '@apollo/client';
import {
    ChannelSearchV2,
    ChannelSearchV2Variables,
} from 'src/apollo/definitions/ChannelSearchV2';
import {
    ChannelSearch,
    ChannelSearchVariables,
} from '../../definitions/ChannelSearch';
import {
    ChannelSearchResult,
    ChannelSearchResultV2,
    selectChannelSearchResults,
    selectChannelSearchResultsV2,
} from '../../selectors/channel';
import searchChannelQuery from './channelSearch.gql';
import searchChannelQueryV2 from './channelSearchV2.gql';

export interface ChannelSearchQueryResult {
    loading: boolean;
    error?: ApolloError;
    data?: ChannelSearchResult[];
}

export interface ChannelSearchQueryResultV2 {
    loading: boolean;
    error?: ApolloError;
    data?: ChannelSearchResultV2;
}

interface ChannelSearchQueryHandler {
    (
        variables: QueryLazyOptions<ChannelSearchVariables>
    ): Promise<ChannelSearchQueryResult>;
}

export type ChannelSearchLazyQueryResult = [
    ChannelSearchQueryHandler,
    ChannelSearchQueryResult
];

export const useLazyChannelSearch = (): ChannelSearchLazyQueryResult => {
    const [doChannelSearch, { data, loading, error }] = useLazyQuery<
        ChannelSearch,
        ChannelSearchVariables
    >(searchChannelQuery);

    const executeSearch = useCallback<ChannelSearchQueryHandler>(
        async args => {
            const { data: searchData } = await doChannelSearch(args);
            return {
                loading: false,
                data:
                    searchData &&
                    selectChannelSearchResults(searchData.channelCatalogSearch),
            };
        },
        [doChannelSearch]
    );

    return [
        executeSearch,
        {
            data: data && selectChannelSearchResults(data.channelCatalogSearch),
            loading,
            error,
        },
    ];
};

export const useChannelSearchQuery = ({
    variables,
    skip,
}: {
    variables: ChannelSearchVariables;
    skip?: boolean;
}): ChannelSearchQueryResult => {
    const { loading, error, data } = useQuery<
        ChannelSearch,
        ChannelSearchVariables
    >(searchChannelQuery, {
        variables,
        skip,
    });

    return {
        loading,
        data: data && selectChannelSearchResults(data.channelCatalogSearch),
        error,
    };
};

export const useChannelSearchQueryV2 = ({
    variables,
    skip,
}: {
    variables: ChannelSearchV2Variables;
    skip?: boolean;
}): ChannelSearchQueryResultV2 => {
    const { loading, error, data } = useQuery<
        ChannelSearchV2,
        ChannelSearchV2Variables
    >(searchChannelQueryV2, {
        variables,
        skip,
    });

    return {
        loading,
        data: data && selectChannelSearchResultsV2(data.channelCatalogSearchV2),
        error,
    };
};
