export const CLASSNAME = 'MetricsOverTime';
export const CLASSNAME_TABLE = `${CLASSNAME}-table`;
export const CLASSNAME_TABLE_TITLE_CELL = `${CLASSNAME_TABLE}-title-cell`;
export const CLASSNAME_TABLE_TITLE_CELL_ONLY = `${CLASSNAME_TABLE}-title-only`;
export const TESTID_CHECKBOX_ALL = 'CheckboxAll';
export const TESTID_CHECKBOX = 'Checkbox';
export const CLASSNAME_TOOLBAR = `${CLASSNAME}-toolbar`;
export const CLASSNAME_CHART = `${CLASSNAME}-chart`;

export const PARAM_HIDDEN_SERIES = 'hiddenSeries';

export const TOTAL_ROW_NAME = 'TOTAL_ROW';
