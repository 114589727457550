export const CLASSNAME = 'SourcesStatusV2';
export const STATUS_ERROR_CLASSNAME = `${CLASSNAME}-error`;
export const STATUS_UPDATED_CLASSNAME = `${CLASSNAME}-updated`;

export const TERMS = {
    ERROR: 'sources.error',
    UPDATED: 'sources.updated',
    STATUS: 'sources.types.status',
    DATA_UP_TO: 'sources.dataUpTo',
    STORES_STATUS: 'sources.modal.storesStatus',
};
