import React, { FC } from 'react';
import { VideoSearchResult } from 'src/apollo/selectors';
import { ROUTE_VIDEO } from 'src/constants';
import { TERM_TAB_YOUTUBE_VIDEO } from 'src/constants/page';
import RecentSearchedItem from 'src/pages/searchES/components/recentlyViewedItems/v2/recentSearchedItem';
import { createHref } from 'src/utils/route';
const VideoRecentSearched: FC<{ item: VideoSearchResult }> = ({
    item: { name, videoId, imageUrl },
}) => {
    return (
        <RecentSearchedItem
            title={name ?? ''}
            type={TERM_TAB_YOUTUBE_VIDEO}
            url={createHref(ROUTE_VIDEO, { id: videoId })}
            imageUrl={imageUrl}
            coverShape="letterbox"
            coverWidth={'53'}
        >
            {videoId && (
                <>
                    &nbsp;&#8209;&nbsp;
                    <span>{videoId}</span>
                </>
            )}
        </RecentSearchedItem>
    );
};

export default VideoRecentSearched;
