import React, { ReactElement } from 'react';
import {
    GridTableProps,
    GridTableSortBy,
    GridTable as SuiteGridTable,
} from '@theorchard/suite-components';
import { formatMessage, useFeatureFlag } from '@theorchard/suite-frontend';
import { OutlineIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import SourceModalContent from 'src/components/gridTable/sources/sourceModalContent';
import SourceTrigger from 'src/components/sourcesStatusV2/sourceTrigger';
import { SortDirection } from 'src/components/table/types';
import { INSIGHTS_SOURCES_TABLE_POPUP_V2 } from 'src/constants/featuresFlags';
import { PAGE_OVERVIEW } from 'src/constants/page';
import { SOURCE_STREAMS, Source, SourceType } from 'src/constants/stores';
import { useRouteParams } from 'src/utils/route';
import './extensions/expandedRowTable';
import {
    ENGAGE_CUSTOMIZE_COLUMNS,
    TABLE_SORTED,
    trackEngagementEvent,
} from 'src/utils/segment';
import GridTableSummaryRow from './summaryRow';
import { ColumnConfig } from './types';

export const CLASSNAME = 'InsightsGridTable';

export interface Props<T>
    extends Omit<GridTableProps<T>, 'onSort' | 'onExport' | 'name'> {
    name: string;
    columnNameToSortNameMap?: Record<string, string>;
    sortBy: GridTableSortBy | GridTableSortBy[];
    sources?: Source[];
    sourcesTypes?: SourceType[];
    onExport?: (visibleColumns: string[]) => void;
    onSort?: (name: string, direction: SortDirection) => void;
}

const GridTable = <T,>({
    className,
    sortBy,
    loadingRows = 20,
    emptyStateTitle = formatMessage('noData.available'),
    columnNameToSortNameMap,
    onSort,
    onExport,
    children,
    stickyHeader,
    sources = [],
    sourcesTypes = [SOURCE_STREAMS],
    ...rest
}: Props<T>): ReactElement<Props<T>> => {
    const isSourcesV2Enabled = useFeatureFlag(INSIGHTS_SOURCES_TABLE_POPUP_V2);
    const [params] = useRouteParams();
    const sortByArray = Array.isArray(sortBy) ? sortBy : [sortBy];
    const sortByMapped: GridTableSortBy[] = columnNameToSortNameMap
        ? sortByArray.map(({ key: sortByKey, ...restSortProps }) => ({
              ...restSortProps,
              key:
                  Object.keys(columnNameToSortNameMap).find(
                      columnName =>
                          columnNameToSortNameMap[columnName] === sortByKey
                  ) || sortByKey,
          }))
        : sortByArray;

    const handleSort = onSort
        ? ([{ key, direction }]: GridTableSortBy[]) => {
              const mappedKey = columnNameToSortNameMap?.[key] || key;

              onSort(mappedKey, direction);
              trackEngagementEvent(TABLE_SORTED, {
                  tab: params.page ?? PAGE_OVERVIEW,
                  column: mappedKey,
                  'sort-direction': direction,
              });
          }
        : undefined;

    const handleExportCsv = onExport
        ? (columns: ColumnConfig[]) => {
              const visibleColumns = columns
                  .filter(
                      ({ visibility }) =>
                          visibility === 'visible' ||
                          visibility === 'visible-locked'
                  )
                  .map(({ name }) => name);
              onExport(visibleColumns);
          }
        : undefined;

    const handleCustomizeSaved = (
        columns: { name: string; visibility: string }[]
    ) => {
        const trackableColumns = columns.reduce(
            (acc, { name, visibility }) => ({ ...acc, [name]: visibility }),
            {}
        );

        trackEngagementEvent(ENGAGE_CUSTOMIZE_COLUMNS, {
            tab: params.page ?? PAGE_OVERVIEW,
            ...trackableColumns,
        });
    };

    const showSource = sources.length > 0;

    return (
        <SuiteGridTable
            testId={CLASSNAME}
            bordered
            customizable
            exportable
            loadingRows={loadingRows}
            emptyStateTitle={emptyStateTitle}
            emptyStateIcon={() => <OutlineIcon name="AnalyticsOutlineIcon" />}
            showSource={showSource}
            SourceComponent={
                <SourceModalContent
                    sources={sources}
                    sourcesTypes={sourcesTypes}
                />
            }
            SourceTriggerComponent={
                isSourcesV2Enabled
                    ? ({ onClick }) => (
                          <SourceTrigger
                              onClick={onClick}
                              sources={sources}
                              sourcesTypes={sourcesTypes}
                          />
                      )
                    : undefined
            }
            sourceModalHeaderText={
                isSourcesV2Enabled
                    ? formatMessage('sources.modal.storesStatus')
                    : undefined
            }
            {...rest}
            className={cx(CLASSNAME, className)}
            onExport={handleExportCsv}
            onSort={handleSort}
            sortBy={sortByMapped}
            onCustomizeSaved={handleCustomizeSaved}
            stickyHeader={stickyHeader}
        >
            {children}
        </SuiteGridTable>
    );
};

GridTable.SummaryRow = GridTableSummaryRow;
GridTable.Column = SuiteGridTable.Column;

export default GridTable;
