import React from 'react';
import { GridTableSortBy } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { SoundRecordingSearchResultItem } from 'src/apollo/selectors';
import GridTable from 'src/components/gridTable';
import { SpotifyWebApi } from 'src/components/spotifyPlayer';
import { EmptyCell } from 'src/components/table';
import { SortDirection } from 'src/components/table/types';
import { NO_RESULT_BODY_TERM, NO_RESULT_TITLE_TERM } from 'src/constants';
import { SEARCH_SONG_SORTING_FIELDS_MAP } from './constants';

export const CLASSNAME = 'SongResultsGridTable';
export const TEST_ID = CLASSNAME;

export interface Props {
    term: string;
    loading?: boolean;
    data?: SoundRecordingSearchResultItem[];
    sortBy: GridTableSortBy;
    paginated?: boolean;
    totalCount?: number;
    page?: number;
    pageSize?: number;
    onPageChange?: (page: number) => void;
    onPageSizeChange?: (size: number) => void;
    onSort: (name: string, direction: SortDirection) => void;
}

const defaultColumnDefs = {
    sortable: true,
};

const SongResultsGridTable: React.FC<Props> = ({
    term,
    loading,
    data,
    sortBy,
    paginated,
    totalCount,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onSort,
}) => (
    <GridTable
        name={CLASSNAME}
        className={CLASSNAME}
        testId={TEST_ID}
        data={data}
        loading={loading}
        header={
            <GridTable.SummaryRow
                itemsCount={totalCount}
                showUpdateIndicator={!!data}
                showExport={false}
                showCustomize={false}
            />
        }
        rowKey="isrc"
        onSort={onSort}
        sortBy={sortBy}
        defaultColumnDefs={defaultColumnDefs}
        columnNameToSortNameMap={SEARCH_SONG_SORTING_FIELDS_MAP}
        emptyStateTitle={formatMessage(NO_RESULT_TITLE_TERM, {
            query: term,
        })}
        emptyStateBody={formatMessage(NO_RESULT_BODY_TERM)}
        paginated={paginated}
        totalCount={totalCount}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        stickyHeader
    >
        <GridTable.Column name="myCatalog" template="myCatalog" />
        <GridTable.Column
            name="name"
            template="songLink"
            title={formatMessage('catalog.songName')}
            minWidth="250px"
            maxWidth="4fr"
            sortable={false}
        />
        <GridTable.Column
            name="artistName"
            template="participantLink"
            title={formatMessage('catalog.artistName')}
            minWidth="150px"
            maxWidth="3fr"
            sortable={false}
        />
        <GridTable.Column
            name="labelNames"
            title={formatMessage('catalog.labelName')}
            template="truncatedText"
            minWidth="150px"
            maxWidth="2fr"
            sortable={false}
        />
        <GridTable.Column
            name="isrc"
            template="truncatedText"
            title={formatMessage('track.metadata.isrc')}
            maxWidth="max-content"
            sortable={false}
        />
        <GridTable.Column
            name="releaseDate"
            template="date"
            title={formatMessage('catalog.releaseDate')}
            maxWidth="max-content"
        />
        <GridTable.Column
            name="streams7Days"
            title={formatMessage('catalog.streams7')}
            template="number"
            minWidth="120px"
        />
        <GridTable.Column
            name="streams28Days"
            title={formatMessage('catalog.streams28')}
            template="number"
            minWidth="120px"
        />
        <GridTable.Column
            name="streamsAllTime"
            title={formatMessage('catalog.streamsAll')}
            template="number"
            minWidth="120px"
        />
        <GridTable.Column
            name="popularity"
            title={formatMessage('chart.popularity')}
            template="number"
            minWidth="auto"
            maxWidth="max-content"
            visibility={'visible'}
        />
        <GridTable.Column
            name="score"
            title={formatMessage('search.score')}
            template="number"
            minWidth="auto"
            maxWidth="max-content"
            visibility={'visible'}
        />
        <GridTable.Column
            name="playButton"
            template="songPlay"
            HeaderText={EmptyCell}
            sortable={false}
            visibility={SpotifyWebApi.isEnabled() ? 'visible' : 'hidden'}
        />
    </GridTable>
);

export default SongResultsGridTable;
