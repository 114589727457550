import { TopVideosQuery } from 'src/apollo/definitions/TopVideosQuery';
import { nonNullable } from 'src/apollo/utils';
import { Entity } from './types';
import { toEntity, getPreviousMonthNames } from './utils';
import { youtubeVideoUrl, selectVideoParticipants } from './video';

export interface CatalogVideo {
    video: Entity & {
        channelOwner?: string;
        videoContentType?: string;
    };
    channel?: Entity;
    participants: Entity[];
    labelName: string;
    allTimeViews: number;
    allTimeRevenue: number;
    avgViewDuration: number;
    month1Views?: number;
    month1Change: number | null;
    month2Views?: number;
    month2Change: number | null;
    month3Views?: number;
    month3Change: number | null;
}

export interface CatalogVideoResults {
    totalCount: number;
    items: CatalogVideo[];
    months: string[];
}

export const selectTopVideos = (data: TopVideosQuery): CatalogVideoResults => {
    const items: CatalogVideo[] = data.topVideos.videos.map(video => ({
        video: {
            ...toEntity({
                id: video.videoId,
                name: video.name,
                imageUrl: youtubeVideoUrl(video.videoId),
            }),
            channelOwner: nonNullable(video.channel?.channelOwner),
            videoContentType: nonNullable(video.videoContentType),
        },
        channel: video.channel
            ? toEntity({
                  id: video.channel.channelId,
                  name: video.channel.name,
              })
            : undefined,
        labelName: video.channel?.vendor?.name ?? '',
        participants: selectVideoParticipants(
            video.globalSoundRecording ? [video.globalSoundRecording] : []
        ),
        allTimeViews: video.analytics.metrics?.views ?? 0,
        allTimeRevenue: video.analytics.metrics?.estimatedGrossRevenue ?? 0,
        avgViewDuration:
            video.analytics.metrics?.averageViewDurationSeconds ?? 0,
        month1Views:
            video.analytics.metrics?.growthPeriods[0].value || undefined,
        month1Change:
            video.analytics.metrics?.growthPeriods[0].growthPercentage ?? 0,
        month2Views:
            video.analytics.metrics?.growthPeriods[1].value || undefined,
        month2Change:
            video.analytics.metrics?.growthPeriods[1].growthPercentage ?? 0,
        month3Views:
            video.analytics.metrics?.growthPeriods[2].value || undefined,
        month3Change:
            video.analytics.metrics?.growthPeriods[2].growthPercentage ?? 0,
    }));

    const lastAvailableDate =
        data.topVideos.videos[0]?.analytics.metrics?.lastAvailableDate;

    return {
        totalCount: data.topVideos.totalCount,
        items,
        months: getPreviousMonthNames(lastAvailableDate),
    };
};
