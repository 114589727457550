import { useContext } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import {
    SongAggregatedRankingsV2Query,
    SongAggregatedRankingsV2QueryVariables,
} from 'src/apollo/definitions/SongAggregatedRankingsV2Query';
import { SongMetadata } from 'src/apollo/selectors';
import { AppContext } from 'src/context';
import { selectSongRankingsV2 } from '../../selectors';
import { useParallelQueries } from '../../utils';
import songAggregatedRankingsV2Query from './songAggregatedRankingsV2.gql';

export const useSongAggregatedRankingsQuery = (song: SongMetadata) => {
    const { chartsById, chartsLoading } = useContext(AppContext);

    const variables = { publicSoundRecordingId: song.publicId ?? '' };

    const { data, loading, error } = useQuery<
        SongAggregatedRankingsV2Query,
        SongAggregatedRankingsV2QueryVariables
    >(songAggregatedRankingsV2Query, {
        variables,
        skip: chartsLoading || !song.publicId,
    });

    return {
        loading: loading || chartsLoading,
        data: data && selectSongRankingsV2(chartsById, data),
        error,
    };
};

export const useSongAggregatedRankingsQueries = (songs: SongMetadata[]) => {
    const client = useApolloClient();
    const { chartsById } = useContext(AppContext);

    const songsArray = Array.isArray(songs) ? songs : [];
    const variables = songsArray.reduce<
        SongAggregatedRankingsV2QueryVariables[]
    >(
        (result, { publicId }) =>
            publicId
                ? [...result, { publicSoundRecordingId: publicId }]
                : result,
        []
    );

    const {
        data = [],
        loading,
        error,
    } = useParallelQueries<
        SongAggregatedRankingsV2Query,
        SongAggregatedRankingsV2QueryVariables
    >(songAggregatedRankingsV2Query, { client, variables });

    const result = songsArray.map(({ isrc, publicId }) => {
        const rankings = data.find(
            ({ publicSoundRecording }) => publicSoundRecording?.id === publicId
        );
        return {
            isrc,
            rankings: rankings
                ? selectSongRankingsV2(chartsById, rankings)
                : [],
        };
    });

    return {
        loading,
        data: result,
        error,
    };
};
