import React, { VFC } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import { ChannelSearchResult } from 'src/apollo/selectors';
import { Entity } from 'src/apollo/selectors/types';
import GridTable from 'src/components/gridTable';

export const CLASSNAME = 'ChannelResultsGridTable';
export const TEST_ID = CLASSNAME;
export const NO_RESULT_BODY_TERM = 'search.channel.noResultBody';
export const NO_RESULT_TITLE_TERM = 'search.channel.noResultTitle';

export interface ChannelSearchResultTableData extends ChannelSearchResult {
    channel: Entity;
}

export interface Props {
    loading?: boolean;
    data?: ChannelSearchResultTableData[];
}

const defaultColumnDefs = {
    sortable: false,
};

const ChannelResultsGridTable: VFC<Props> = ({ loading, data }) => {
    return (
        <GridTable
            name={CLASSNAME}
            className={CLASSNAME}
            testId={TEST_ID}
            data={data}
            sortBy={{ key: '', direction: 'asc' }}
            defaultColumnDefs={defaultColumnDefs}
            rowKey={({ channelId, isPartOfCatalog }) =>
                `${channelId}-${isPartOfCatalog}`
            }
            loading={loading}
            header={
                <GridTable.SummaryRow
                    itemsCount={data?.length}
                    showUpdateIndicator={!!data}
                    showExport={false}
                    showCustomize={false}
                />
            }
            emptyStateTitle={formatMessage(NO_RESULT_TITLE_TERM)}
            emptyStateBody={formatMessage(NO_RESULT_BODY_TERM)}
            emptyStateIcon={() => null}
            stickyHeader
        >
            <GridTable.Column name="myCatalog" template="myCatalog" />
            <GridTable.Column
                name="video"
                title={formatMessage('channel.youtubeChannel')}
                template="thumbnailChannelLink"
                minWidth="190px"
                sortable={false}
            />
            <GridTable.Column
                name="labelName"
                title={formatMessage('catalog.labelName')}
                template="truncatedText"
                minWidth="150px"
                sortable={false}
            />
            <GridTable.Column
                name="channelId"
                title={formatMessage('channel.id')}
                template="truncatedText"
                minWidth="250px"
                maxWidth="max-content"
                sortable={false}
            />
        </GridTable>
    );
};

export default ChannelResultsGridTable;
