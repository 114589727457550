import { useRef } from 'react';
import { get, isEqual } from 'lodash';

export const filterData = <T>(
    data?: (T | null | undefined | '' | 0 | false)[] | null
) => (data ? (data.filter(item => item) as T[]) : []);

export const nonNullable = <T>(data?: T | null) =>
    data === null ? undefined : (data as T);

export const notEmpty = <TValue>(
    value: TValue | null | undefined
): value is TValue => value !== null && value !== undefined;

export const assertDate = <T, K extends keyof T>(obj: T, name: K): string => {
    const { formatted } = get(obj, name, {});
    if (!formatted)
        throw new Error(`"${String(name)}" is an invalid date object`);
    return formatted;
};

export const assertValue = <T, K extends keyof T>(
    obj: T | null | undefined,
    name: K
): Exclude<Required<T>[K], null | undefined> => {
    const value = get(obj, name);
    if (value === undefined || value === null)
        throw new Error(`"${String(name)}" is null or undefined`);
    return value;
};

export function useDeepMemo<TKey, TValue>(
    memoFn: () => TValue,
    key: TKey
): TValue {
    const ref = useRef<{ key: TKey; value: TValue }>();

    if (!ref.current || !isEqual(key, ref.current.key))
        ref.current = { key, value: memoFn() };

    return ref.current.value;
}
