import { useQuery } from '@apollo/client';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import { usePrefetchQuery } from 'src/apollo/utils';
import { INSIGHTS_SEARCH_UPDATES } from 'src/constants/featuresFlags';
import { ITEMS_LIMIT, ITEMS_LIMIT_UPDATED } from 'src/constants/recentlyViewed';
import {
    RecentlyVisitedItems,
    RecentlyVisitedItemsVariables,
} from '../definitions/RecentlyVisitedItems';
import { selectRecentlyViewedItems } from '../selectors';
import recentlyViewedQuery from './recentlyViewed.gql';

export const useRecentlyViewedItemsQuery = (
    variables: RecentlyVisitedItemsVariables
) => {
    const { data, loading, error } = useQuery<
        RecentlyVisitedItems,
        RecentlyVisitedItemsVariables
    >(recentlyViewedQuery, { variables });

    return {
        data: selectRecentlyViewedItems(data),
        loading,
        error,
    };
};

export const usePrefetchRecentlyViewedItemsQuery = () => {
    const isSearchFFEnabled = useFeatureFlag(INSIGHTS_SEARCH_UPDATES);
    const limit = isSearchFFEnabled ? ITEMS_LIMIT_UPDATED : ITEMS_LIMIT;

    usePrefetchQuery<RecentlyVisitedItems, RecentlyVisitedItemsVariables>(
        recentlyViewedQuery,
        { variables: { limit } }
    );
};
