import { Identity, useIdentity } from '@theorchard/suite-frontend';
import { INSIGHTS_EMPLOYEE_OVERRIDE } from 'src/constants/featuresFlags';

export const useEmployeeIdentity = (): Identity => {
    const identity = useIdentity();
    const isEmployee =
        identity.isEmployee || identity.features[INSIGHTS_EMPLOYEE_OVERRIDE];
    return {
        ...identity,
        isEmployee,
    };
};
