import React, { ReactElement } from 'react';
import cx from 'classnames';
import GridTable, { GridTableProps } from 'src/components/gridTable';
import NewFeatureHighlight from 'src/components/newFeatureHighlight';
import { SOURCE_STREAMS, Source, SourceType } from 'src/constants/stores';

export const CLASSNAME = 'CatalogGridTable';
export const CLASSNAME_PAGINATION = `${CLASSNAME}-pagination`;

export interface Props<T> extends GridTableProps<T> {
    isFiltered?: boolean;
    itemsCount?: number;
    sources?: Source[];
    sourcesTypes?: SourceType[];
    showTikTokIndicator?: boolean;
    showCustomize?: boolean;
    onClearFilters: VoidFunction;
    onExportCsv?: (visibleColumns: string[]) => void;
}

const CatalogGridTable = <T,>({
    className,
    data,
    isFiltered = false,
    itemsCount,
    sources = [],
    sourcesTypes = [SOURCE_STREAMS],
    showTikTokIndicator,
    showCustomize = true,
    onClearFilters,
    onExportCsv,
    children,
    ...rest
}: Props<T>): ReactElement<Props<T>> => {
    return (
        <GridTable
            testId={CLASSNAME}
            className={cx(CLASSNAME, CLASSNAME_PAGINATION, className)}
            data={data}
            onClearFilters={onClearFilters}
            onExport={onExportCsv}
            headerRight={
                showTikTokIndicator
                    ? () => (
                          <NewFeatureHighlight
                              message="catalog.new"
                              platform="tiktok"
                          />
                      )
                    : undefined
            }
            totalCount={itemsCount}
            sources={sources}
            sourcesTypes={sourcesTypes}
            showClearFilters={isFiltered}
            showUpdateIndicator={Boolean(data?.length)}
            customizable={showCustomize}
            stickyHeader
            {...rest}
        >
            {children}
        </GridTable>
    );
};

export default CatalogGridTable;
