import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useAppConfig as useSuiteAppConfig } from '@theorchard/suite-frontend';
import { ChartDefinition, ChartDictionary } from 'src/constants/charts';
import { InsightsAppConfig } from './types';

interface ApplicationContext {
    chartsLoading: boolean;
    chartsError?: Error;
    charts: ChartDefinition[];
    chartsById: ChartDictionary;
}

export type PageUrlParameters = Record<string, string | string[]>;
export type ApplicationUrlParameters = Record<string, PageUrlParameters>;

export interface ApplicationUrlContextProps {
    appUrlParams: ApplicationUrlParameters;
    setAppUrlParams?: Dispatch<SetStateAction<ApplicationUrlParameters>>;
}

export const AppContext = React.createContext<ApplicationContext>({
    chartsLoading: false,
    charts: [],
    chartsById: {},
});

export const useAppConfig = () => useSuiteAppConfig<InsightsAppConfig>();

export const useCharts = () => {
    const { chartsById, charts, chartsLoading, chartsError } =
        useContext(AppContext);
    return { chartsById, charts, chartsLoading, chartsError };
};

export const ApplicationUrlContext =
    React.createContext<ApplicationUrlContextProps>({
        appUrlParams: {},
    });

export const useApplicationUrlContext = () => useContext(ApplicationUrlContext);
