import React, { FC } from 'react';
import { CountryFlag } from '@theorchard/suite-icons';
import { ParticipantSearchResultItem } from 'src/apollo/selectors';
import { ROUTE_PARTICIPANT } from 'src/constants';
import RecentSearchedItem from 'src/pages/searchES/components/recentlyViewedItems/v2/recentSearchedItem';
import { TERM_ARTIST } from 'src/pages/searchES/constants';
import { createHref } from 'src/utils/route';

const ArtistRecentSearched: FC<{ item: ParticipantSearchResultItem }> = ({
    item: { name, id, imageUrl, country },
}) => {
    return (
        <RecentSearchedItem
            title={name ?? ''}
            type={TERM_ARTIST}
            url={createHref(ROUTE_PARTICIPANT, { id })}
            imageUrl={imageUrl}
            coverShape="round"
            coverWidth={'30'}
        >
            {country && (
                <>
                    &nbsp;&#8209;&nbsp;
                    <CountryFlag countryCode={country} />
                </>
            )}
        </RecentSearchedItem>
    );
};

export default ArtistRecentSearched;
