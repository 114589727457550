import {
    STORE_ID_AMAZON_MUSIC,
    STORE_ID_APPLE_MUSIC,
    STORE_ID_SPOTIFY,
} from 'src/constants/stores';

export const STREAMS = 'streams';
export const DOWNLOADS = 'downloads';
export const SKIPS = 'skips';
export const SAVES = 'saves';
export const TRACK_DOWNLOADS = 'trackDownloads';
export const ALBUM_DOWNLOADS = 'albumDownloads';
export const SKIP_RATE = 'skipRate';

export const STORE = 'store';
export const SOURCE_OF_STREAMS = 'sourceofstreams';
export const COUNTRIES = 'countries';
export const TRACK = 'track';
export const SONG = 'song';
export const ALL_VIEWS = 'allViews';
export const PRODUCT = 'product';
export const CONTENT = 'content';
export const SUBSCRIPTION = 'subscription';
export const AD_SUPPORTED = 'adSupported';
export const PROJECT = 'project';
export const IMPRINT = 'imprint';
export const SUBACCOUNT = 'subaccount';
export const PARTICIPANT = 'artist';
export const ACCOUNT = 'account';
export const TIKTOK = 'tiktok';
export const ACTIVE_TOTALS = 'activeTotals';
export const PASSIVE_TOTALS = 'passiveTotals';

export const VIEWS = 'views';
export const WATCH_TIME = 'watchtime';
export const AVG_DURATION = 'avgduration';
export const AVG_PERCENTAGE = 'avgpercentage';
export const COMMENTS = 'comments';

export const OTHER_ROW_NAME = 'OTH';

export const TOP_ORDERED_STORES_IDS = [
    STORE_ID_SPOTIFY,
    STORE_ID_APPLE_MUSIC,
    STORE_ID_AMAZON_MUSIC,
];

export const SOS_COLORS: Record<string, number> = {
    passive: 0,
    active: 1,
    collection: 2,
    unknown: 11,
};

export const SOS_COLORS_V2: Record<string, number> = {
    passive: 1,
    active: 0,
    collection: 2,
    unknown: 3,
};
