import React, { VFC } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import { ProductSearchResult } from 'src/apollo/selectors';
import GridTable from 'src/components/gridTable';
import { NO_RESULT_BODY_TERM, NO_RESULT_TITLE_TERM } from 'src/constants';

export const CLASSNAME = 'ProductResultsGridTable';
export const TEST_ID = CLASSNAME;

export interface Props {
    term: string;
    loading?: boolean;
    data?: ProductSearchResult;
}

const defaultColumnDefs = {
    sortable: false,
};

const ProductResultsGridTable: VFC<Props> = ({ term, loading, data }) => {
    return (
        <GridTable
            name={CLASSNAME}
            className={CLASSNAME}
            testId={TEST_ID}
            data={data?.items}
            header={
                <GridTable.SummaryRow
                    itemsCount={data?.totalCount}
                    showUpdateIndicator={!!data}
                    showExport={false}
                    showCustomize={false}
                />
            }
            loading={loading}
            sortBy={{ key: '', direction: 'asc' }}
            defaultColumnDefs={defaultColumnDefs}
            rowKey={({ upc, type }) => `${upc}-${type}`}
            emptyStateTitle={formatMessage(NO_RESULT_TITLE_TERM, {
                query: term,
            })}
            emptyStateBody={formatMessage(NO_RESULT_BODY_TERM)}
            stickyHeader
        >
            <GridTable.Column name="myCatalog" template="myCatalog" />
            <GridTable.Column
                name="productName"
                title={formatMessage('catalog.productName')}
                template="thumbnailProductLink"
                minWidth="250px"
                maxWidth="2fr"
                sortable={false}
            />

            <GridTable.Column
                name="artists"
                template="participantLink"
                title={formatMessage('product.metadata.artist')}
                minWidth="140px"
                maxWidth="3fr"
            />

            <GridTable.Column
                name="labelName"
                title={formatMessage('catalog.labelName')}
                template="truncatedText"
                minWidth="150px"
                maxWidth="2fr"
                sortable={false}
            />

            <GridTable.Column
                name="type"
                title={formatMessage('product.metadata.type')}
                template="truncatedText"
                minWidth="auto"
                maxWidth="max-content"
                sortable={false}
            />

            <GridTable.Column
                name="upc"
                title={formatMessage('product.metadata.upc')}
                template="truncatedText"
                minWidth="auto"
                maxWidth="max-content"
                sortable={false}
            />
        </GridTable>
    );
};

export default ProductResultsGridTable;
