import React, { useMemo } from 'react';
import { SuitePlugin } from '@theorchard/suite-frontend';
import {
    useChartsQuery,
    usePrefetchRecentlySelectedCountryFiltersQuery,
    usePrefetchTopGlobalSoundRecordingsQuery,
    usePrefetchTopAccountsQuery,
    usePrefetchTopAccountsCountQuery,
    usePrefetchPreSavedMarketsQuery,
    usePrefetchAnalyticsDataAvailabilityQuery,
} from 'src/apollo/queries';
import { AppContext } from 'src/context';
import './dependencies/gridTable';

const PreloadWrapper: React.FC = ({ children }) => {
    usePrefetchTopAccountsCountQuery();
    usePrefetchPreSavedMarketsQuery();
    usePrefetchAnalyticsDataAvailabilityQuery();
    usePrefetchTopAccountsQuery();
    usePrefetchTopGlobalSoundRecordingsQuery();
    usePrefetchRecentlySelectedCountryFiltersQuery();

    const {
        data: charts,
        loading: chartsLoading,
        error: chartsError,
    } = useChartsQuery();

    const appContext = useMemo(
        () => ({
            chartsLoading,
            chartsError,
            charts: charts?.charts || [],
            chartsById: charts?.chartsById || {},
        }),
        [charts, chartsLoading, chartsError]
    );

    return (
        <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
    );
};

const PreloadedDataProvider: SuitePlugin = {
    Wrapper: ({ children, loaded }) => {
        // Don't pre-load until the apollo client and identity is available.
        if (!loaded) return <>{children}</>;

        return <PreloadWrapper>{children}</PreloadWrapper>;
    },
};

export default PreloadedDataProvider;
