import React, { FC } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import { VideoSearchResult } from 'src/apollo/selectors';
import { Entity } from 'src/apollo/selectors/types';
import GridTable from 'src/components/gridTable';

export const CLASSNAME = 'VideoResultsGridTable';
export const TEST_ID = CLASSNAME;
export const NO_RESULT_BODY_TERM = 'search.video.noResultBody';
export const NO_RESULT_TITLE_TERM = 'search.video.noResultTitle';

export interface VideoSearchResultTableData extends VideoSearchResult {
    video: Entity;
}

export interface Props {
    loading?: boolean;
    data?: VideoSearchResultTableData[];
}

const defaultColumnDefs = {
    sortable: false,
};

const VideoResultsGridTable: FC<Props> = ({ loading, data }) => {
    return (
        <GridTable
            name={CLASSNAME}
            className={CLASSNAME}
            testId={TEST_ID}
            data={data}
            sortBy={{ key: '', direction: 'asc' }}
            defaultColumnDefs={defaultColumnDefs}
            rowKey={({ videoId, isPartOfCatalog }) =>
                `${videoId}-${isPartOfCatalog}`
            }
            loading={loading}
            header={
                <GridTable.SummaryRow
                    itemsCount={data?.length}
                    showUpdateIndicator={!!data}
                    showExport={false}
                    showCustomize={false}
                />
            }
            emptyStateTitle={formatMessage(NO_RESULT_TITLE_TERM)}
            emptyStateBody={formatMessage(NO_RESULT_BODY_TERM)}
            emptyStateIcon={() => null}
            stickyHeader
        >
            <GridTable.Column name="myCatalog" template="myCatalog" />
            <GridTable.Column
                name="video"
                title={formatMessage('video.video')}
                template="thumbnailVideoLink"
                minWidth="190px"
                maxWidth="2fr"
                sortable={false}
            />
            <GridTable.Column
                name="youtubeChannel"
                title={formatMessage('channel.youtubeChannel')}
                template="channelLinkCell"
                minWidth="190px"
                maxWidth="2fr"
                sortable={false}
            />
            <GridTable.Column
                name="labelName"
                title={formatMessage('catalog.labelName')}
                template="truncatedText"
                minWidth="150px"
                maxWidth="2fr"
                sortable={false}
            />
            <GridTable.Column
                name="videoId"
                title={formatMessage('video.id')}
                template="truncatedText"
                minWidth="150px"
                maxWidth="1fr"
                sortable={false}
            />
        </GridTable>
    );
};

export default VideoResultsGridTable;
