import React from 'react';
import { CoverArt, GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';
import PlaylistLinkCell from './playlistLinkCell';

type coverArtSizeType = number | undefined;

GridTable.defineColumnTemplate('thumbnailPlaylist', {
    align: 'left',
    Cell: ({ data, ...rest }) => {
        const {
            column: { definition },
        } = rest;
        return (
            <div className="ThumbnailPlaylistLinkCell CoverArtCell">
                <CoverArt
                    url={data.imageLocation}
                    width={
                        (get(definition, [
                            'data-coverArtSize',
                        ]) as coverArtSizeType) || '30'
                    }
                />
                <PlaylistLinkCell data={data} {...rest} />
            </div>
        );
    },
});
