import { ApolloError, useQuery } from '@apollo/client';
import {
    GlobalProductSearchV2,
    GlobalProductSearchV2Variables,
} from 'src/apollo/definitions/GlobalProductSearchV2';
import {
    selectProductSearch,
    ProductSearchResult,
} from 'src/apollo/selectors/product';
import {
    GlobalProductSearch,
    GlobalProductSearchVariables,
} from '../../definitions/GlobalProductSearch';
import globalProductSearch from './globalProductSearch.gql';
import globalProductSearchV2 from './globalProductSearchV2.gql';

export interface ProductSearchQueryResult {
    loading: boolean;
    error?: ApolloError;
    data?: ProductSearchResult;
}

export interface ProductSearchQueryResultV2 {
    loading: boolean;
    error?: ApolloError;
    data?: ProductSearchResult;
}

export const useProductSearchQuery = ({
    variables,
    skip,
}: {
    variables: GlobalProductSearchVariables;
    skip?: boolean;
}): ProductSearchQueryResult => {
    const { loading, error, data } = useQuery<
        GlobalProductSearch,
        GlobalProductSearchVariables
    >(globalProductSearch, {
        variables,
        skip,
    });

    return {
        loading,
        data: data && selectProductSearch(data),
        error,
    };
};

export const useProductSearchQueryV2 = ({
    variables,
    skip,
}: {
    variables: GlobalProductSearchV2Variables;
    skip?: boolean;
}): ProductSearchQueryResultV2 => {
    const { loading, error, data } = useQuery<
        GlobalProductSearchV2,
        GlobalProductSearchV2Variables
    >(globalProductSearchV2, {
        variables,
        skip,
    });

    return {
        loading,
        data: data && selectProductSearch(data),
        error,
    };
};
