export const TYPE_PRODUCT = 'product';
export const TYPE_PROJECT = 'project';

export const PRODUCT_TYPE_PHYSICAL_AUDIO = 'physical audio';
export const PRODUCT_TYPE_DIGITAL_AUDIO = 'digital audio';
export const PRODUCT_TYPE_MUSIC_VIDEO = 'music video';
export const PRODUCT_TYPE_FILM = 'film';

export const LABELS_LIMIT = 10;
export const LABEL_MANAGERS_LIMIT = 10;
export const CHANNELS_LIMIT = 10;

export const SME_DISTRIBUTION_TYPES = ['SMEAnalyticsDummy', 'SwitchboardDummy'];

export const CSV_ROW_LIMIT = 200;
