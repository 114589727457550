import React from 'react';
import { GridTableSortBy } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { ParticipantSearchResultItem } from 'src/apollo/selectors';
import GridTable from 'src/components/gridTable';
import { SortDirection } from 'src/components/table/types';
import { NO_RESULT_BODY_TERM, NO_RESULT_TITLE_TERM } from 'src/constants';
import { SEARCH_ARTIST_SORTING_FIELDS_MAP } from './constants';

export const CLASSNAME = 'ArtistResultsGridTable';
export const TEST_ID = CLASSNAME;

export interface Props {
    term: string;
    loading?: boolean;
    data?: ParticipantSearchResultItem[];
    totalCount?: number;
    sortBy: GridTableSortBy;
    onSort: (name: string, direction: SortDirection) => void;
}

const defaultColumnDefs = {
    sortable: true,
};

const ArtistResultsGridTable: React.FC<Props> = ({
    term,
    loading,
    data,
    totalCount,
    sortBy,
    onSort,
}) => (
    <GridTable
        name={CLASSNAME}
        className={CLASSNAME}
        testId={TEST_ID}
        data={data}
        loading={loading}
        header={
            <GridTable.SummaryRow
                itemsCount={totalCount}
                showUpdateIndicator={!!data}
                showExport={false}
                showCustomize={false}
            />
        }
        rowKey="id"
        onSort={onSort}
        sortBy={sortBy}
        defaultColumnDefs={defaultColumnDefs}
        columnNameToSortNameMap={SEARCH_ARTIST_SORTING_FIELDS_MAP}
        emptyStateTitle={formatMessage(NO_RESULT_TITLE_TERM, {
            query: term,
        })}
        emptyStateBody={formatMessage(NO_RESULT_BODY_TERM)}
        stickyHeader
    >
        <GridTable.Column name="myCatalog" template="myCatalog" />
        <GridTable.Column
            name="artistName"
            template="thumbnailParticipantLink"
            title={formatMessage('catalog.artistName')}
            minWidth="250px"
            maxWidth="4fr"
            sortable={false}
        />
        <GridTable.Column
            name="country"
            template="countryFlagWithTooltip"
            HeaderText={() => null}
            sortable={false}
            maxWidth="max-content"
        />
        <GridTable.Column
            name="labelNames"
            title={formatMessage('catalog.labelName')}
            template="truncatedText"
            minWidth="150px"
            maxWidth="3fr"
            sortable={false}
        />
        <GridTable.Column
            name="monthlyListeners"
            template="number"
            title={formatMessage('chart.monthlyListeners')}
            maxWidth="max-content"
        />

        <GridTable.Column
            name="streams7Days"
            title={formatMessage('catalog.streams7')}
            template="number"
            minWidth="120px"
        />
        <GridTable.Column
            name="streams28Days"
            title={formatMessage('catalog.streams28')}
            template="number"
            minWidth="120px"
        />
        <GridTable.Column
            name="streamsAllTime"
            title={formatMessage('catalog.streamsAll')}
            template="number"
            minWidth="120px"
        />
        <GridTable.Column
            name="popularity"
            title={formatMessage('chart.popularity')}
            template="number"
            minWidth="auto"
            maxWidth="max-content"
            visibility={'visible'}
        />
        <GridTable.Column
            name="score"
            title={formatMessage('search.score')}
            template="number"
            minWidth="auto"
            maxWidth="max-content"
            visibility={'visible'}
        />
    </GridTable>
);

export default ArtistResultsGridTable;
