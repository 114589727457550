import React, { CSSProperties, FC, useRef, useState } from 'react';
import { BsOverlay, BsTooltip } from '@theorchard/suite-components';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { createHref, createHrefWithSearchParams } from 'src/utils/route';

export type Placement = 'top' | 'bottom' | 'right' | 'left';
export interface TruncatedTextProps {
    className?: string;
    value?: string;
    useLink?: boolean;
    route?: string;
    params?: object;
    maxLength?: number;
    placement?: Placement;
    onClick?: () => void;
    disableTooltipPointerEvents?: boolean;
    withOverlay?: boolean;
    shouldLogPageVisit?: boolean;
    shouldUseSearchQuery?: boolean;
    lines?: number;
}

export const CLASSNAME = 'TruncatedText';
export const CLASSNAME_TRIGGER = `${CLASSNAME}-span`;
export const CLASSNAME_DISABLE_POINTER = `${CLASSNAME}-disable-pointer`;
export const TESTID = CLASSNAME;

const TruncatedText: FC<TruncatedTextProps> = ({
    className,
    value,
    useLink,
    route,
    maxLength,
    params = {},
    placement = 'bottom',
    onClick,
    children,
    disableTooltipPointerEvents = false,
    withOverlay = true,
    shouldLogPageVisit = false,
    shouldUseSearchQuery = false,
    lines = 0,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef(null);

    const multiline = lines > 1;
    const isValueOverflow = !!maxLength && !!value && value.length > maxLength;
    const text = isValueOverflow
        ? `${value?.substring(0, maxLength)}...`
        : value;

    const handleFocus = (element: HTMLElement) => {
        const isOverflowing = multiline
            ? element.offsetHeight < element.scrollHeight
            : element.offsetWidth < element.scrollWidth;
        setShowTooltip(isOverflowing || isValueOverflow);
    };

    const handleBlur = () => setShowTooltip(false);

    const overlay = (
        <BsOverlay
            target={target.current}
            show={showTooltip}
            placement={placement}
        >
            {props => (
                <BsTooltip
                    id={CLASSNAME}
                    className={cx({
                        [CLASSNAME_DISABLE_POINTER]:
                            disableTooltipPointerEvents,
                    })}
                    {...props}
                >
                    {value}
                </BsTooltip>
            )}
        </BsOverlay>
    );

    const style: CSSProperties | undefined = multiline
        ? {
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: lines,
              whiteSpace: 'normal',
          }
        : undefined;

    let path;
    let searchParams;

    if (shouldUseSearchQuery && useLink && route) {
        ({ path, search: searchParams } = createHrefWithSearchParams(
            route,
            params
        ));
    }

    return useLink && route ? (
        <Link
            className={cx(CLASSNAME, className)}
            to={{
                pathname: shouldUseSearchQuery
                    ? path
                    : createHref(route, params),
                search: shouldUseSearchQuery ? searchParams : '',
                state: { shouldLogPageVisit },
            }}
            ref={target}
            onClick={onClick}
            onMouseEnter={e => handleFocus(e.currentTarget)}
            onFocus={e => handleFocus(e.currentTarget)}
            onMouseLeave={handleBlur}
            onBlur={handleBlur}
            style={style}
            data-testid={TESTID}
        >
            {children || text}
            {withOverlay && overlay}
        </Link>
    ) : (
        <div
            className={cx(CLASSNAME, className)}
            data-testid={TESTID}
            ref={target}
            onMouseEnter={e => handleFocus(e.currentTarget)}
            onFocus={e => handleFocus(e.currentTarget)}
            onMouseLeave={handleBlur}
            onBlur={handleBlur}
            style={style}
        >
            {children || text}
            {withOverlay && overlay}
        </div>
    );
};

export default TruncatedText;
