import { selectImageUrl, isSMEProduct } from 'src/apollo/selectors/utils';
import { filterData } from 'src/apollo/utils';
import { RecentlyVisitedItems } from '../definitions/RecentlyVisitedItems';
import { ChannelSearchResult } from './channel';
import { ParticipantSearchResultItem } from './participantSearch';
import { ProductSearchResultItem, selectLabelParticipations } from './product';
import { SoundRecordingSearchResultItem } from './soundRecordingSearch';
import { toEntity } from './utils';
import { VideoSearchResult, youtubeVideoUrl } from './video';

export type RecentItem =
    | ChannelSearchResult
    | ParticipantSearchResultItem
    | ProductSearchResultItem
    | SoundRecordingSearchResultItem
    | VideoSearchResult;

export const selectRecentlyViewedItems = (
    data: RecentlyVisitedItems | undefined
): RecentItem[] =>
    filterData(
        data?.recentlyVisitedItems.map(item => {
            if ('upc' in item) {
                const product: ProductSearchResultItem = {
                    upc: item.upc ?? '',
                    productName: item.productName,
                    imageLocation:
                        selectImageUrl(
                            item.globalProduct?.publicImageUrl,
                            item.imageLocation,
                            isSMEProduct(item.notForDistribution)
                        ) || '',
                    type: item.format ?? '',
                    isPartOfCatalog: true,
                    labelName: item.labelName ?? '',
                    participants: selectLabelParticipations(
                        item.labelParticipations
                    ),
                    releaseDate: '',
                };
                return product;
            }

            if ('channelId' in item) {
                const channel: ChannelSearchResult = {
                    name: item.name ?? undefined,
                    channelId: item.channelId,
                    subscriberCount: item.subscriberCount ?? undefined,
                    thumbnailUrl: item.thumbnailUrl ?? undefined,
                    isPartOfCatalog: item.isPartOfCatalog,
                    labelName: item.vendor?.name,
                };
                return channel;
            }

            if ('id' in item) {
                const participant: ParticipantSearchResultItem = {
                    id: item.id,
                    name: item.name ?? undefined,
                    imageUrl: item.imageUrl ?? undefined,
                    monthlyListeners:
                        item.participant?.socialStatsV2?.monthlyListeners ?? 0,
                    isPartOfCatalog: item.isPartOfCatalog,
                    country: item.countryCode ?? undefined,
                };
                return participant;
            }

            if ('isrc' in item && item.types?.includes('music')) {
                const participants = filterData(item.globalParticipants).map(
                    toEntity
                );
                const product = item.catalogProducts?.[0];
                const imageUrl = item?.imageUrl || product?.imageLocation || '';
                const song: SoundRecordingSearchResultItem = {
                    id: item.isrc,
                    isrc: item.isrc,
                    name: item.soundRecordingName,
                    participants,
                    isPartOfCatalog: Boolean(item.labelSoundRecordings.length),
                    imageUrl,
                    releaseDate: item.releaseDateV2 || '',
                };
                return song;
            }

            if ('isrc' in item && item.types?.includes('video')) {
                const [mainVideo] = filterData(item.videos);

                const channel = mainVideo?.channel;
                const video: VideoSearchResult = {
                    videoId: mainVideo?.videoId ?? '',
                    name: item.soundRecordingName,
                    channel: channel
                        ? toEntity({
                              name: channel?.name,
                              id: channel?.channelId,
                          })
                        : undefined,
                    isPartOfCatalog: mainVideo?.isPartOfCatalog,
                    imageUrl: youtubeVideoUrl(mainVideo?.videoId),
                };

                return video;
            }

            if ('videoId' in item) {
                const video: VideoSearchResult = {
                    videoId: item.videoId,
                    name: item.name ?? '',
                    isPartOfCatalog: item.isPartOfCatalog,
                    imageUrl: youtubeVideoUrl(item.videoId),
                };

                return video;
            }

            return undefined;
        })
    );
