export const PAGE_OVERVIEW = 'overview';
export const PAGE_SONGS = 'songs';
export const PAGE_SONG = 'song';
export const PAGE_SOCIAL = 'social';
export const PAGE_CHARTS = 'charts';
export const PAGE_PLAYLISTS = 'playlists';
export const PAGE_PRODUCTS = 'products';
export const PAGE_VIDEOS = 'videos';
export const PAGE_PLAYLISTS_STREAMING = 'playlists-streaming';
export const PAGE_UGC = 'ugc';
export const PAGE_TIKTOK = 'tiktok';
export const PAGE_ARTISTS = 'artists';
export const PAGE_ARTIST = 'artist';
export const PAGE_CHANNELS = 'channels';
export const PAGE_TRACKS = 'tracks';
export const PAGE_ACCOUNTS = 'accounts';

export const TERM_TAB_OVERVIEW = 'tabs.overview';
export const TERM_TAB_CHARTS = 'tabs.charts';
export const TERM_TAB_SOCIAL = 'tabs.social';
export const TERM_TAB_TRACKS = 'tabs.tracks';
export const TERM_TAB_PLAYLISTS = 'tabs.playlists';
export const TERM_TAB_TIKTOK = 'tabs.tiktok';
export const TERM_TAB_PLAYLISTS_STREAMING = 'tabs.playlistsStreaming';
export const TERM_TAB_SONGS = 'catalog.songs';
export const TERM_TAB_ARTISTS = 'catalog.artists';
export const TERM_TAB_VIDEOS = 'video.videos';
export const TERM_TAB_VIDEO = 'video.video';
export const TERM_TAB_YOUTUBE_VIDEO = 'video.youtubeVideo';
export const TERM_TAB_YOUTUBE_VIDEOS = 'video.youtubeVideos';
export const TERM_TAB_CHANNELS = 'channel.youtubeChannels';
export const TERM_TAB_CHANNEL = 'channel.youtubeChannel';
export const TERM_TAB_PRODUCTS = 'catalog.products';
export const TERM_TAB_ACCOUNTS = 'catalog.accounts';
