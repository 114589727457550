import { useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { STREAMS } from 'src/constants/metrics';
import {
    SoundRecordingTopCountriesByDownloads as DownloadsQuery,
    SoundRecordingTopCountriesByDownloadsVariables as DownloadsVariables,
} from '../../definitions/SoundRecordingTopCountriesByDownloads';
import {
    SoundRecordingTopCountriesByStreams as StreamsQuery,
    SoundRecordingTopCountriesByStreamsVariables as StreamsVariables,
} from '../../definitions/SoundRecordingTopCountriesByStreams';
import {
    selectCountryDownloadsRecords,
    selectCountryDownloadsSources,
    selectCountryStreamSources,
    selectCountryStreamsRecords,
} from '../../selectors';
import { useDisplayLimitedData, useParallelQueries } from '../../utils';
import SoundRecordingCountryDownloadsQuery from './soundRecordingTopCountriesByDownloads.gql';
import SoundRecordingCountryStreamsQuery from './soundRecordingTopCountriesByStreams.gql';

export const useSoundRecordingTopCountriesQuery = (
    metric: string,
    limit: number,
    variables: (StreamsVariables & DownloadsVariables)[]
) => {
    const client = useApolloClient();
    const { showCount, count } = useDisplayLimitedData(limit);
    const isStreams = metric === STREAMS;

    const {
        loading: streamsLoading,
        data: streamsData,
        error: streamsError,
    } = useParallelQueries<StreamsQuery, StreamsVariables>(
        SoundRecordingCountryStreamsQuery,
        { variables, client, skip: !isStreams }
    );

    const {
        loading: downloadsLoading,
        data: downloadsData,
        error: downloadsError,
    } = useParallelQueries<DownloadsQuery, DownloadsVariables>(
        SoundRecordingCountryDownloadsQuery,
        { variables, client, skip: isStreams }
    );

    const { data, sources } = useMemo(() => {
        if (!streamsData && !downloadsData) return {};

        return {
            data: isStreams
                ? selectCountryStreamsRecords(streamsData ?? [])
                : selectCountryDownloadsRecords(downloadsData ?? []),
            sources: isStreams
                ? selectCountryStreamSources(streamsData ?? [])
                : selectCountryDownloadsSources(downloadsData ?? []),
        };
    }, [downloadsData, isStreams, streamsData]);

    return {
        loading:
            (streamsLoading && isStreams) || (downloadsLoading && !isStreams),
        data,
        error: isStreams ? streamsError : downloadsError,
        showCount,
        count,
        sources,
    };
};
