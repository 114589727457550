import { orderBy } from 'lodash';
import {
    SoundRecordingTopCountriesByDownloads,
    SoundRecordingTopCountriesByDownloads_globalSoundRecordingByIsrc_analytics_topCountriesByDownloads_countries as CountryDownloads,
    SoundRecordingTopCountriesByDownloads_globalSoundRecordingByIsrc_analytics as CountryDownloadAnalytics,
} from 'src/apollo/definitions/SoundRecordingTopCountriesByDownloads';
import {
    SoundRecordingTopCountriesByStreams,
    SoundRecordingTopCountriesByStreams_globalSoundRecordingByIsrc_analytics_topCountriesByStreams_countries as CountryStreams,
    SoundRecordingTopCountriesByStreams_globalSoundRecordingByIsrc_analytics as CountryAnalytics,
} from 'src/apollo/definitions/SoundRecordingTopCountriesByStreams';
import { assertValue } from 'src/apollo/utils';
import { selectSources } from 'src/utils/sources';

export interface TopCountry {
    code: string;
    value: number;
    position: number;
    highlight: boolean;
}

interface AnalyticsType<T> {
    isrc: string;
    analytics: T | null;
}
interface SoundRecordingType<T> {
    globalSoundRecordingByIsrc: AnalyticsType<T> | null;
}

const selectCountryRecord = (
    code: string,
    value: number,
    position: number,
    highlight = false
) => ({
    code,
    value,
    position,
    highlight,
});

const selectCountryRecords = (countries: CountryStreams[]) =>
    countries.map(({ countryCode, streams }, index) =>
        selectCountryRecord(countryCode, streams, index + 1)
    );

const selectCountryDownloadRecords = (countries: CountryDownloads[]) =>
    countries.map(({ countryCode, downloads }, index) =>
        selectCountryRecord(countryCode, downloads, index + 1)
    );

const selectEmptyCountryRecord = (countryCode: string) =>
    selectCountryRecord(countryCode, 0, 0, true);

const selectSelectedCountries = (
    countries: TopCountry[],
    selectedCountries: string[]
) => [
    ...selectedCountries
        .filter(code => !countries.find(c => c.code === code))
        .map(code => selectEmptyCountryRecord(code)),
    ...countries
        .filter(c => selectedCountries.includes(c.code))
        .map(c => ({ ...c, highlight: true })),
];

export interface CountryRecords {
    isrc: string;
    countries: TopCountry[];
}

const selectCountryTypedRecords = <T extends SoundRecordingType<V>, V>(
    data: T[],
    selector: (analytics: V) => TopCountry[]
): CountryRecords[] => {
    const records = data.map(record => {
        const analytics = record?.globalSoundRecordingByIsrc?.analytics;
        let countries: TopCountry[] = [];
        if (analytics) countries = selector(analytics);

        return {
            isrc: assertValue(record.globalSoundRecordingByIsrc, 'isrc'),
            countries,
        };
    });
    return records;
};

export const selectCountryStreamsRecords = (
    data: SoundRecordingTopCountriesByStreams[]
): CountryRecords[] =>
    selectCountryTypedRecords<
        SoundRecordingTopCountriesByStreams,
        CountryAnalytics
    >(data, ({ topCountriesByStreams: { countries } = {} }) =>
        selectCountryRecords(countries || [])
    );

export const selectCountryDownloadsRecords = (
    data: SoundRecordingTopCountriesByDownloads[]
): CountryRecords[] =>
    selectCountryTypedRecords<
        SoundRecordingTopCountriesByDownloads,
        CountryDownloadAnalytics
    >(data, ({ topCountriesByDownloads: { countries } = {} }) =>
        selectCountryDownloadRecords(countries || [])
    );

export const selectCountryTotals = (songs: CountryRecords[]) =>
    songs.map(data =>
        data.countries.reduce((sum, item) => sum + item.value, 0)
    );

export const selectFilteredCountries = (
    songs: CountryRecords[],
    countryFilter: string[],
    totalStreams: number[]
) =>
    songs.map((data, index) => {
        const totalStreamValue = totalStreams[index] || 0;
        const cutoffPoint = Math.round(totalStreamValue / 1000);
        return {
            ...data,
            countries: [
                ...orderBy(
                    selectSelectedCountries(data.countries, countryFilter),
                    'value',
                    'desc'
                ),
                ...data.countries.filter(c => c.value >= cutoffPoint),
            ],
        };
    });

export const selectCountryStreamSources = (
    series: SoundRecordingTopCountriesByStreams[]
) => {
    const [first] = series;
    const sources =
        first?.globalSoundRecordingByIsrc?.analytics?.topCountriesByStreams
            ?.sources ?? [];

    return selectSources(sources);
};

export const selectCountryDownloadsSources = (
    series: SoundRecordingTopCountriesByDownloads[]
) => {
    const [first] = series;
    const sources =
        first?.globalSoundRecordingByIsrc?.analytics?.topCountriesByDownloads
            ?.sources ?? [];

    return selectSources(sources);
};
