import React, { FC } from 'react';
import { formatDate, formatMessage } from '@theorchard/suite-frontend';
import cx from 'classnames';
import PlatformIcon from 'src/components/platformIcon';
import {
    CLASSNAME,
    STATUS_ERROR_CLASSNAME,
    STATUS_UPDATED_CLASSNAME,
    TERMS,
} from 'src/components/sourcesStatusV2/constants';
import { SOURCE_DATE_FORMAT } from 'src/constants';
import { SourceError, Source, STORES_BY_ID } from 'src/constants/stores';

export const CLASSNAME_ROW = `${CLASSNAME}-row`;
export const CLASSNAME_STATUS = `${CLASSNAME}-status`;
export const CLASSNAME_STORE = `${CLASSNAME}-store`;
export const CLASSNAME_DATE = `${CLASSNAME}-date`;

const SourceItem: FC<Source> = ({ latestDate, storeName, storeId, error }) => {
    const displayDate = latestDate
        ? formatDate(latestDate, SOURCE_DATE_FORMAT)
        : 'N/A';

    const renderStatusIndicator = (error?: SourceError) => (
        <div
            className={cx(
                'dot',
                'dot-md',
                error ? STATUS_ERROR_CLASSNAME : STATUS_UPDATED_CLASSNAME
            )}
        />
    );

    return (
        <div
            key={storeId}
            data-testid={`store-${storeId}`}
            className={CLASSNAME_ROW}
        >
            <div className={CLASSNAME_STORE}>
                <PlatformIcon
                    platform={STORES_BY_ID[storeId]}
                    storeId={storeId}
                />
                <div>{storeName}</div>
            </div>
            <div className={CLASSNAME_STATUS}>
                {renderStatusIndicator(error)}
                {formatMessage(error ? TERMS.ERROR : TERMS.UPDATED)}
            </div>
            <div className={CLASSNAME_DATE}>{displayDate}</div>
        </div>
    );
};

export default SourceItem;
