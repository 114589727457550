import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { GlyphIcon } from '@theorchard/suite-icons';
import { get } from 'lodash';
import CountryFlag from 'src/components/countryFlag';

export const CLASSNAME = 'StorefrontCountCellV2';
export const CLASSNAME_COUNT = `${CLASSNAME}-count`;
export const CLASSNAME_ICON = `${CLASSNAME}-icon`;

interface ToggleHandler {
    (playlistId: string, storeId?: number, isrc?: string): void;
}

GridTable.defineColumnTemplate('storefrontCountriesV2', {
    align: 'left',
    minWidth: 'min-content',
    maxWidth: 'max-content',
    Cell: ({
        data: { isrc, playlistId, storeId, storefrontCount, topMarket },
        rowKey,
        context: {
            props: { expandedRowTables },
        },
        column: { definition },
    }) => {
        const size = get(definition, 'data-size') || 16;

        if (!storefrontCount)
            return <CountryFlag code={topMarket} size={size} />;

        const isExpanded = expandedRowTables
            ? rowKey in expandedRowTables
            : false;
        const handleToggle = get(definition, 'data-onToggle') as
            | ToggleHandler
            | undefined;
        const iconName = isExpanded ? 'chevronUp' : 'chevronDown';

        return (
            <div
                role="presentation"
                className={CLASSNAME}
                onClick={() => handleToggle?.(playlistId, storeId, isrc)}
            >
                <span className={CLASSNAME_COUNT}>{storefrontCount}</span>
                <GlyphIcon
                    name={iconName}
                    className={CLASSNAME_ICON}
                    size={16}
                />
            </div>
        );
    },
});
