import { useEffect, useRef, useState } from 'react';
import {
    ApolloClient,
    ApolloError,
    OperationVariables,
    WatchQueryFetchPolicy,
} from '@apollo/client';
import { asError } from '@theorchard/suite-frontend';
import { DocumentNode } from 'graphql';
import { isEqual } from 'lodash';
import { filterData, useDeepMemo } from './data';
import { flattenError } from './error';

interface ParallelQueriesState<T> {
    loading: boolean;
    data?: T[];
    error?: Error;
}

interface ParallelQueriesOptions<TVariables> {
    variables: TVariables[];
    client: ApolloClient<object>;
    skip?: boolean;
    batch?: boolean | string;
    fetchPolicy?: WatchQueryFetchPolicy;
}

export const useParallelQueries = <
    TData,
    TVariables extends OperationVariables = OperationVariables
>(
    graphqlQuery: DocumentNode,
    { variables, client, skip, fetchPolicy }: ParallelQueriesOptions<TVariables>
) => {
    const lastRequestedVars = useRef<TVariables[] | null>();
    const memoVars = useDeepMemo(() => variables, [variables]);
    const [response, setResponse] = useState<ParallelQueriesState<TData>>({
        loading: variables.length > 0,
        data: undefined,
        error: undefined,
    });
    const lastData = useRef<TData[]>();
    const lastLoading = useRef(response.loading);

    useEffect(() => {
        const queries = skip
            ? []
            : memoVars.map(vars =>
                  client.watchQuery<TData, TVariables>({
                      query: graphqlQuery,
                      variables: vars,
                      fetchPolicy,
                  })
              );

        lastRequestedVars.current = memoVars;

        const handleResultError = <T>(error: T) => {
            setResponse({ loading: false, error: asError(error) });
        };

        const handleResultChanged = () => {
            if (lastRequestedVars.current !== memoVars) return;

            try {
                const allResults = queries.map(query =>
                    query.getCurrentResult()
                );

                const withError = allResults.find(
                    result => result.errors && result.errors.length > 0
                );
                const nextData = allResults.length
                    ? filterData(allResults.map(result => result.data))
                    : undefined;
                const nextLoading = allResults.some(result => result.loading);

                const dataHasChanged = !isEqual(lastData.current, nextData);
                const loadingHasChanged = lastLoading.current !== nextLoading;

                if (loadingHasChanged) lastLoading.current = nextLoading;
                if (dataHasChanged) lastData.current = nextData;

                if (loadingHasChanged || dataHasChanged || withError)
                    setResponse({
                        loading: lastLoading.current,
                        error:
                            withError &&
                            flattenError(
                                new ApolloError({
                                    graphQLErrors: withError.errors,
                                })
                            ),
                        data: lastData.current,
                    });
            } catch (error) {
                handleResultError(error);
            }
        };

        handleResultChanged();
        const subscriptions = queries.map(query =>
            query.subscribe(handleResultChanged, handleResultError)
        );

        return () => {
            lastRequestedVars.current = null;
            subscriptions.forEach(sub => sub.unsubscribe());
        };
    }, [client, graphqlQuery, memoVars, skip, fetchPolicy]);
    return response;
};
