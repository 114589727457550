import {
    ApolloError,
    useLazyQuery,
    useQuery,
    QueryLazyOptions,
} from '@apollo/client';
import {
    VideoSearchV2Variables,
    VideoSearchV2,
} from 'src/apollo/definitions/VideoSearchV2';
import {
    VideoSearch,
    VideoSearchVariables,
} from '../../definitions/VideoSearch';
import {
    VideoSearchResult,
    selectVideoSearchResults,
    selectVideoSearchResultsV2,
    VideoCatalogSearchV2,
} from '../../selectors';
import searchVideoQuery from './videoSearch.gql';
import searchVideoQueryV2 from './videoSearchV2.gql';

export interface VideoSearchQueryResult {
    loading: boolean;
    error?: ApolloError;
    data?: VideoSearchResult[];
}

export interface VideoSearchQueryResultV2 {
    loading: boolean;
    error?: ApolloError;
    data?: VideoCatalogSearchV2;
}

export type VideoSearchLazyQueryResult = [
    (variables: QueryLazyOptions<VideoSearchVariables>) => void,
    VideoSearchQueryResult
];

export const useLazyVideoSearch = (): VideoSearchLazyQueryResult => {
    const [doVideoSearch, { data, loading, error }] = useLazyQuery<
        VideoSearch,
        VideoSearchVariables
    >(searchVideoQuery);

    return [
        doVideoSearch,
        {
            data: data && selectVideoSearchResults(data.videoCatalogSearch),
            loading,
            error,
        },
    ];
};

export const useVideoSearchQuery = ({
    variables,
    skip,
}: {
    variables: VideoSearchVariables;
    skip?: boolean;
}): VideoSearchQueryResult => {
    const { loading, error, data } = useQuery<
        VideoSearch,
        VideoSearchVariables
    >(searchVideoQuery, {
        variables: {
            ...variables,
        },
        skip,
    });

    return {
        loading,
        data: data && selectVideoSearchResults(data.videoCatalogSearch),
        error,
    };
};

export const useVideoSearchQueryV2 = ({
    variables,
    skip,
}: {
    variables: VideoSearchV2Variables;
    skip?: boolean;
}): VideoSearchQueryResultV2 => {
    const { loading, error, data } = useQuery<
        VideoSearchV2,
        VideoSearchV2Variables
    >(searchVideoQueryV2, {
        variables,
        skip,
    });

    return {
        loading,
        data: data && selectVideoSearchResultsV2(data.videoCatalogSearchV2),
        error,
    };
};
