import { useQuery } from '@apollo/client';
import {
    SettingsProfileQuery,
    SettingsProfileQueryVariables,
} from '../definitions/SettingsProfileQuery';
import settingsProfileQuery from './settingsProfile.gql';

export const useSettingsProfileQuery = (identityId: string) => {
    const { data, loading } = useQuery<
        SettingsProfileQuery,
        SettingsProfileQueryVariables
    >(settingsProfileQuery, { variables: { identityId } });

    const [settingsProfile] = data?.identityById?.profiles ?? [];

    return {
        loading,
        settingsProfile,
    };
};
