import React, { useState, useEffect } from 'react';
import { Button, Modal } from '@theorchard/suite-components';
import {
    BRAND_AWAL,
    BRAND_ORCHARD,
    useIdentity,
    formatMessage,
} from '@theorchard/suite-frontend';
import { useLocation } from 'react-router-dom';
import { INSIGHTS_ORCHARD_GO_BANNER } from 'src/constants/featuresFlags';
import { isMobile, getMobileAppLink } from 'src/utils/mobile';
import {
    trackOrchardGoBannerChoseBrowser,
    trackOrchardGoBannerClose,
    trackOrchardGoBannerDisplaying,
    trackOrchardGoBannerDownload,
} from 'src/utils/segment/orchardGoBanner';
import PhoneIcon from './phoneIcon';
import StarIcon from './starIcon';

const CLASSNAME = 'OrchardGoBanner';
const CLASSNAME_LOGO = `${CLASSNAME}-logo`;

// TODO: move this to suite icons
const OrchardGoIcon = () => {
    const { defaultBrand } = useIdentity();
    const isAwalUser = defaultBrand === BRAND_AWAL;
    const brand = isAwalUser ? BRAND_AWAL : BRAND_ORCHARD;

    return (
        <img
            src={`https://cdn.theorchard.io/assets/${brand}/icons/go_app.svg`}
            alt=""
        />
    );
};

const storageKey = 'orchardGoBannerClosed';

const OrchardGoBanner = () => {
    const { pathname } = useLocation();
    const [page, tab] = pathname.slice(1).split('/');
    const { id, email, features, defaultBrand } = useIdentity();
    // TODO check if use has mobile app installed;
    const [isAvailable] = useState(true);
    const [isOpen, setOpen] = useState(!sessionStorage.getItem(storageKey));

    useEffect(() => {
        if (isMobile() && isAvailable && isOpen)
            trackOrchardGoBannerDisplaying({ id, email, page, tab });
    }, []);

    if (!features[INSIGHTS_ORCHARD_GO_BANNER] || !isMobile() || !isAvailable)
        return null;

    const orchardGoIcon = <OrchardGoIcon />;
    const title = (
        <div className={CLASSNAME_LOGO}>
            {orchardGoIcon}
            <span>{defaultBrand === BRAND_AWAL ? 'AWALGo' : 'OrchardGo'}</span>
        </div>
    );

    const close = () => {
        setOpen(false);
        sessionStorage.setItem(storageKey, '1');
    };
    const handleDownload = () => {
        trackOrchardGoBannerDownload({ id, email, page, tab });
        close();
    };
    const handleCancel = () => {
        trackOrchardGoBannerChoseBrowser({ id, email, page, tab });
        close();
    };
    const closeModal = () => {
        trackOrchardGoBannerClose({ id, email, page, tab });
        close();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            className={CLASSNAME}
            title={title}
        >
            <div className={`${CLASSNAME}-main`}>
                <div className={`${CLASSNAME}-title`}>
                    <p>{formatMessage('orchardGoBanner.title')}</p>
                    <p>{formatMessage('orchardGoBanner.subTitle')}</p>
                </div>
                <div className={`${CLASSNAME}-phone`}>
                    <PhoneIcon />
                </div>
                <div className={`${CLASSNAME}-tooltip`}>
                    <div className={`${CLASSNAME}-tooltip-text`}>
                        <div>{orchardGoIcon}</div>
                        <div>
                            <p>
                                {formatMessage('orchardGoBanner.tooltipTitle')}
                            </p>
                            <p>
                                {formatMessage(
                                    'orchardGoBanner.tooltipSubTitle'
                                )}
                            </p>
                        </div>
                    </div>
                    <div className={`${CLASSNAME}-tooltip-bottom1`} />
                    <div className={`${CLASSNAME}-tooltip-bottom2`} />
                </div>
                <StarIcon className={`${CLASSNAME}-star1`} />
                <StarIcon className={`${CLASSNAME}-star2`} />
                <StarIcon className={`${CLASSNAME}-star3`} />
            </div>
            <div className={`${CLASSNAME}-footer`}>
                <Button
                    variant="primary"
                    onClick={handleDownload}
                    href={getMobileAppLink(defaultBrand)}
                >
                    {formatMessage('orchardGoBanner.download')}
                </Button>
                <Button variant="link" onClick={handleCancel}>
                    {formatMessage('orchardGoBanner.closeBanner')}
                </Button>
            </div>
        </Modal>
    );
};

export default OrchardGoBanner;
