import React, { FC } from 'react';
import { ChannelSearchResult } from 'src/apollo/selectors';
import { ROUTE_CHANNEL } from 'src/constants';
import RecentSearchedItem from 'src/pages/searchES/components/recentlyViewedItems/v2/recentSearchedItem';
import { TERM_YOUTUBE_CHANNEL } from 'src/pages/searchES/constants';
import { createHref } from 'src/utils/route';
const ChannelRecentSearched: FC<{ item: ChannelSearchResult }> = ({
    item: { name, channelId, thumbnailUrl },
}) => {
    return (
        <RecentSearchedItem
            title={name ?? ''}
            type={TERM_YOUTUBE_CHANNEL}
            url={createHref(ROUTE_CHANNEL, { id: channelId })}
            imageUrl={thumbnailUrl}
            coverShape="round"
            coverWidth={'30'}
        />
    );
};

export default ChannelRecentSearched;
