import {
    STORE_APPLE_MUSIC,
    STORE_SPOTIFY,
    STORE_YOUTUBE,
} from 'src/constants/stores';

export const TOTAL_LOAD_LIMIT = 1000;
export const CHARTS_PAGE_SIZE = 50;

export const COUNTRY = 'country';
export const POSITION = 'position';
export const CHANGE = 'change';
export const CATALOG = 'catalog';
export const BRANDS = 'brands';
export const SONG = 'song';
export const ARTIST = 'artist';
export const CHANNEL = 'channel';
export const VIEWS = 'views';
export const IMPRINT = 'imprint';
export const SHAZAMS = 'shazams';
export const SHAZAMS_GRID = 'totalShazams';
export const STREAMS = 'streams';
export const STREAMS_TOTAL = 'streamsTotal';
export const STREAMS_TOTAL_GRID = 'totalStreams';
export const RELEASE_DATE = 'releaseDate';
export const PEAK_POSITION = 'peakPosition';
export const PEAK_TIMESTAMP = 'peakTimestamp';
export const TIME_ON_CHART = 'timeOnChart';
export const EXPANDER = 'expander';
export const PLAY_BUTTON = 'playButton';
export const VIDEO_TITLE = 'videoTitle';
export const ALBUM_TITLE = 'albumTitle';
export const LABEL = 'label';
export const TRACKS_NUMBER = 'tracksNumber';
export const TRACKS_NUMBER_GRID = 'numTracks';

export const COLUMNS_TO_HIDE: Record<string, string[]> = {
    linemusic: [IMPRINT, RELEASE_DATE],
    soundcloud: [RELEASE_DATE],
    recochoku: [IMPRINT, RELEASE_DATE],
    youtube: [
        IMPRINT,
        RELEASE_DATE,
        ARTIST,
        SONG,
        ALBUM_TITLE,
        LABEL,
        TRACKS_NUMBER,
    ],
    itunes: [IMPRINT, SONG, VIEWS, CHANNEL, VIDEO_TITLE],
};

export const PLATFORMS_TO_SHOW_BRANDS_V2 = [
    STORE_SPOTIFY,
    STORE_APPLE_MUSIC,
    STORE_YOUTUBE,
];

export const PLATFORMS_TO_SHOW_BRANDS_FILTER = [
    STORE_SPOTIFY,
    STORE_APPLE_MUSIC,
];
