import { useApolloClient } from '@apollo/client';
import { selectParticipantResourceAccess } from 'src/apollo/selectors';
import { useParallelQueries } from 'src/apollo/utils';
import {
    GlobalParticipantResourceAccess,
    GlobalParticipantResourceAccessVariables,
} from '../../definitions/GlobalParticipantResourceAccess';
import globalParticipantResourceAccessQuery from './globalParticipantResourceAccess.gql';

export const useParticipantResourceAccess = (
    variables: GlobalParticipantResourceAccessVariables[]
) => {
    const client = useApolloClient();
    const { loading, data, error } = useParallelQueries<
        GlobalParticipantResourceAccess,
        GlobalParticipantResourceAccessVariables
    >(globalParticipantResourceAccessQuery, { variables, client });

    return {
        data: selectParticipantResourceAccess(data),
        loading,
        error,
    };
};
