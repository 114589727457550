import { formatMessage } from '@theorchard/suite-frontend';
import dayjs from 'dayjs';
import { DeltaGrowthPeriod } from 'src/apollo/definitions/globalTypes';
import { getPeriodName } from 'src/apollo/selectors/filters';
import { SONG_DATE_FORMAT } from 'src/constants/index';
import { QUERY_PARAM_CURRENT, QUERY_PARAM_PAST } from 'src/constants/params';

export const PAST_1 = 'past1';
export const PAST_7 = 'past7';
export const PAST_28 = 'past28';
export const PAST_3M = 'past3Months';
export const PAST_6M = 'past6Months';
export const PAST_365 = 'past365';
export const FIRST_7 = 'first7';
export const FIRST_28 = 'first28';
export const FIRST_6M = 'first6Months';
export const FIRST_365 = 'first365';
export const ALL_TIME = 'allTime';
export const CUSTOM = 'custom';
export const DATE_PERIODS = [
    PAST_7,
    PAST_28,
    PAST_6M,
    PAST_365,
    FIRST_7,
    FIRST_28,
    FIRST_6M,
    FIRST_365,
    ALL_TIME,
];
export const ABSOLUTE_PERIODS = [
    PAST_7,
    PAST_28,
    PAST_6M,
    PAST_365,
    ALL_TIME,
    CUSTOM,
];
export const RELATIVE_PERIODS = [FIRST_7, FIRST_28, FIRST_6M, FIRST_365];
export const LARGE_PERIODS = [PAST_6M, FIRST_6M, PAST_365, FIRST_365, ALL_TIME];
export const PERIODS: Record<string, [string, { days: number }?]> = {
    [PAST_1]: ['period.pastDay'],
    [PAST_7]: ['period.pastDays', { days: 7 }],
    [PAST_28]: ['period.pastDays', { days: 28 }],
    [PAST_3M]: ['period.past3Months'],
    [PAST_6M]: ['period.past6Months'],
    [PAST_365]: ['period.pastYear'],
    [FIRST_7]: ['period.firstDays', { days: 7 }],
    [FIRST_28]: ['period.firstDays', { days: 28 }],
    [FIRST_6M]: ['period.first6Months'],
    [FIRST_365]: ['period.firstYear'],
    [ALL_TIME]: ['period.allTime'],
    [CUSTOM]: ['period.customDateRange'],
};

export const PERIODS_API: Record<string, Record<string, string>> = {
    [PAST_1]: { graphql: 'streams1Day', sort: 'streams_1_day' },
    [PAST_7]: { graphql: 'streams7Days', sort: 'streams_7_days' },
    [PAST_28]: { graphql: 'streams28Days', sort: 'streams_28_days' },
    [PAST_6M]: { graphql: 'streams183Days', sort: 'streams_183_days' },
    [PAST_365]: { graphql: 'streams365Days', sort: 'streams_365_days' },
    [ALL_TIME]: { graphql: 'streamsAllTime', sort: 'streams_all_time' },
};

export const GROWTH_PERIODS: Record<string, string> = {
    [PAST_1]: DeltaGrowthPeriod._1_DAY,
    [PAST_7]: DeltaGrowthPeriod._7_DAYS,
    [PAST_28]: DeltaGrowthPeriod._28_DAYS,
    [PAST_6M]: DeltaGrowthPeriod._183_DAYS,
    [PAST_365]: DeltaGrowthPeriod._365_DAYS,
};

export const getPeriodGraphQL = (period: string) => PERIODS_API[period].graphql;
export const getPeriodSort = (period: string) => PERIODS_API[period].sort;
export const getGrowthPeriod = (period: string) => GROWTH_PERIODS[period];
export const getSelectedCustomPeriod = (start?: string, end?: string) =>
    `${dayjs(start).format(SONG_DATE_FORMAT)} - ${dayjs(end).format(
        SONG_DATE_FORMAT
    )}`;

export const getDatePeriodName = (
    datePeriod: string,
    startDate?: string,
    endDate?: string
) => {
    if (datePeriod === CUSTOM)
        return getSelectedCustomPeriod(startDate, endDate);

    return getPeriodName(datePeriod);
};

export const DEFAULT_START_DATE = '1999-01-01';
export const HIGH_WATERMARK = 'HIGHWATERMARK';
export const ALL_TIME_DATE_PARAM = 'ALL_TIME';

export const QUERY_PARAM_DATE_PICKER_START = 'startDate';
export const QUERY_PARAM_DATE_PICKER_END = 'endDate';

export const CUSTOM_PERIOD_STORAGE_KEY = 'customPeriod';

export const PERIOD_CURRENT_TEXT = formatMessage('current');
export const PERIOD_PAST_TEXT = formatMessage('past');
export const PERIODS_TOGGLE_GROUP_ITEMS = [
    { label: PERIOD_CURRENT_TEXT, value: QUERY_PARAM_CURRENT },
    { label: PERIOD_PAST_TEXT, value: QUERY_PARAM_PAST },
];
