import { uniqBy } from 'lodash';
import { selectAccounts } from 'src/apollo/selectors/utils';
import { filterData, nonNullable } from 'src/apollo/utils';
import { EMPTY_CHAR } from 'src/constants';
import { GlobalParticipantDropdownSearchES } from '../definitions/GlobalParticipantDropdownSearchES';
import { GlobalParticipantSearchES } from '../definitions/GlobalParticipantSearchES';

export interface ParticipantSearchResultItem {
    id: string;
    name?: string;
    imageUrl?: string;
    monthlyListeners: number;
    isPartOfCatalog: boolean;
    country?: string;
}

export interface ParticipantSearchResult {
    totalCount: number;
    items: ParticipantSearchResultItem[];
}

export type ParticipantDropdownSearchResultItem = Pick<
    ParticipantSearchResultItem,
    'id' | 'name' | 'imageUrl'
>;
export interface ParticipantDropdownSearchResult {
    items: ParticipantDropdownSearchResultItem[];
}

export const selectGlobalParticipantDropdownSearchESResults = (
    data: GlobalParticipantDropdownSearchES
): ParticipantDropdownSearchResult => {
    const deduped = uniqBy(
        filterData(data.globalParticipantSearchES.items),
        ({ item }) => item.id
    );

    return {
        items: deduped.map(({ item }) => ({
            id: item.id,
            name: item.name ?? undefined,
            imageUrl: item.imageUrl ?? undefined,
        })),
    };
};

export const selectGlobalParticipantSearchESResults = (
    data: GlobalParticipantSearchES
): ParticipantSearchResult => {
    const deduped = uniqBy(
        filterData(data.globalParticipantSearchES.items),
        p => p.item.id
    );

    return {
        totalCount: data.globalParticipantSearchES.totalCount,
        items: deduped.map(({ item, score }) => {
            const labelNames =
                item.labels?.map(l => l.vendorName).join(', ') ?? null;

            const accounts =
                item?.labels?.map(item => ({
                    id: item.vendorId,
                    vendorId: item.vendorId,
                    subaccountId: item.subaccountId ?? 0,
                    name: item.subaccountName ?? item.vendorName,
                    vendorName: item.vendorName,
                    labelName: nonNullable(labelNames),
                })) ?? null;

            return {
                id: item.id,
                name: item.name ?? undefined,
                imageUrl: item.imageUrl ?? undefined,
                monthlyListeners: item.socialStats.monthlyListeners ?? 0,
                popularity: item.socialStats.popularity ?? 0,
                isPartOfCatalog: item.isPartOfCatalog,
                labelNames: labelNames || EMPTY_CHAR,
                score,
                streams7Days: item.usageStats.streams7Days,
                streams28Days: item.usageStats.streams28Days,
                streamsAllTime: item.usageStats.streamsAllTime,
                country: item.countryCode ?? undefined,
                accounts: accounts || EMPTY_CHAR,
            };
        }),
    };
};

export const selectGlobalParticipantSearchESResultsV2 = (
    data: GlobalParticipantSearchES
): ParticipantSearchResult => {
    const deduped = uniqBy(
        filterData(data.globalParticipantSearchES.items),
        p => p.item.id
    );

    return {
        totalCount: data.globalParticipantSearchES.totalCount,
        items: deduped.map(({ item, score }) => {
            const labelNames =
                item.labels?.map(l => l.vendorName).join(', ') ?? null;

            const accounts = item?.globalParticipant?.catalogLabelsV2
                ? selectAccounts(
                      item.globalParticipant.catalogLabelsV2 as [],
                      labelNames
                  )
                : null;

            return {
                id: item.id,
                name: item.name ?? undefined,
                imageUrl: item.imageUrl ?? undefined,
                monthlyListeners: item.socialStats.monthlyListeners ?? 0,
                popularity: item.socialStats.popularity ?? 0,
                isPartOfCatalog: item.isPartOfCatalog,
                labelNames: labelNames || EMPTY_CHAR,
                score,
                streams7Days: item.usageStats.streams7Days,
                streams28Days: item.usageStats.streams28Days,
                streamsAllTime: item.usageStats.streamsAllTime,
                country: item.countryCode ?? undefined,
                accounts: accounts || EMPTY_CHAR,
            };
        }),
    };
};
